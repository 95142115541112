/* Module: S */
.questionnaires__link {
    font-size: pxToRem(21);
    padding-left: pxToRem(35);
    padding-right: pxToRem(75);
    line-height: 1.45;

    &:after {
        height: pxToRem(18);
        width: pxToRem(18);
        right: pxToRem(30);
    }
}
