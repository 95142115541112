/* Module: S */
.alert__content {
    border-top: 0;
    border-left: 1px solid #363636;
    padding-top: 0;
    padding-left: 1.5rem;
    margin-top: 0;
    margin-left: pxToRem(120);
    margin-right: pxToRem(20);

    &:before {
        left: pxToRem(-85);
        top: 50%;
    }

    &:after {
        left: pxToRem(-120);
        top: 50%;
    }
}

.alert__close {
    top: 50%;
    right: pxToRem(15);
    margin-top: pxToRem(-18);
}
