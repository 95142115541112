/* Module: S */
.c-accordion__title {
    padding-top: pxToRem(21);
    padding-bottom: pxToRem(21);

    h3 {
        font-size: pxToRem(16);
        font-weight: 600;
        line-height: 1.85;
        padding-left: pxToRem(21);
        padding-right: pxToRem(55);
    }

    .c-accordion:not(.js-is-tabs) & {
        &::after {
            height: pxToRem(18);
            width: pxToRem(18);
            right: pxToRem(25);
            background-size: 18px;
            margin-top: pxToRem(-9);
        }
    }
}

.c-accordion__panel {
    font-size: pxToRem(18);
    line-height: 1.65;
}

.c-accordion__panel__inner {
    padding: pxToRem(40) 0 pxToRem(35) 0;
}
