/* Module: S */
.suggestions__container {
    padding-top: pxToRem(75);
    padding-bottom: pxToRem(92);
    background-color: $section--bg;
}

.suggestion__question {
    margin-bottom: pxToRem(52);
    font-size: pxToRem(36);
    text-align: left;
}

.suggestion__heading {
    font-size: pxToRem(20);
    padding: pxToRem(15) pxToRem(20);
    bottom: pxToRem(30);
}

.suggestion__icon {
    height: pxToRem(30);
    width: pxToRem(30);
    margin-left: pxToRem(20);
    margin-top: 0;
}
