/**
 * version: {{version}}
 * file:    {{file}}
 * author:  Squiz Australia
 * updated: {{date}}
 *
 * Table of Contents
 *
 * - Reset
 * - Utilities
 * - General
 * - Content
 * - Modules
    {{toc}}
 */

// These imports have no CSS output, but provide variables, mixins and helpers to aid in construction of
// a design cutup.
@import "boilerplate.scss";

/*
--------------------
Reset (normalize.css)
--------------------
*/

@import "normalize.scss";
// This import disabled by default due to rendering issues, particularly with IE where some text doesn't render at all
//@import "normalize-opentype.scss";
// Box size all the things
// https://squizmap.squiz.net/imp-bp/6400
// http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
// == Start box-sizing reset ==
*,
*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

html {
    @include sq-border-box;
}

// == End box-sizing reset ==

/*
--------------------
Utilities
--------------------
*/

@import "utilities.scss";

/*
--------------------
General
--------------------
*/

// Taken from HTML 5 BP
::-moz-selection {
    background: $content-selection-highlight;
    text-shadow: none;
}

::selection {
    background: $content-selection-highlight;
    text-shadow: none;
}

html,
body {
    min-height: 100%;
    height: 100%;
}

body {
    overflow-y: scroll;
    background: $global-bg;
    color: $base-font-colour;
    font-size: $base-font-pixel + px;
    font-family: $base-font-family;
    line-height: 1.65;
    padding: 0;
    margin: 0;
    &.inner__page {
        background: $inner-bg;
    }
}

img,
figure {
    max-width: 100%;
    &.pull-right,
    &.pull-left {
        float: none;
        margin-bottom: 1rem
    }
}

.figure-caption {
    img {
        width: 100%;
        display: block;
    }
    figcaption {
        margin-top: pxToRem(6);
        background-color: #d9d9d9;
        padding: pxToRem(5) pxToRem(12);
    }
}

// Reset fonts for relevant elements (taken from bootstrap)
input,
button,
select,
textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

a {
    color: #0076a2;
    text-decoration: none;
    &:hover,
    &:focus {
        text-decoration: underline;
    }
    #main & {
        &[href$=".doc"],
        &[href$=".docx"],
        &[href$=".pdf"],
        &[href$=".rtf"],
        &[href$=".exe"] {
            &:after {
                display: inline-block;
                color: #fff;
                background-color: #0086B8;
                border-radius: pxToRem(3);
                font-size: pxToRem(14);
                font-weight: 600;
                line-height: 1;
                padding: pxToRem(2) pxToRem(4);
                margin-left: pxToRem(8);
                vertical-align: middle;
                margin-top: pxToRem(-3);
                margin-left: 3px;
            }
        }
        &[href$=".doc"],
        &[href$=".docx"],
        &[href$=".pdf"],
        &[href$=".rtf"],
        &[href$=".exe"] {
            &:before {
                margin-left: 3px;
            }
        }
        &[href$=".doc"]:after {
            content: 'DOC'
        }
        &[href$=".docx"]:after {
            content: 'DOCX'
        }
        &[href$=".doc"],
        &[href$=".docx"] {
            &:before {
                background-image: url({{file_dest}}/icon-doc.svg)
            }
        }
        &[href$=".pdf"] {
            &:after {
                content: 'PDF'
            }
            &:before {
                background-image: url({{file_dest}}/icon-pdf.svg)
            }
        }
        &[href$=".rtf"] {
            &:after {
                content: 'RTF'
            }
        }
        &[href$=".exe"] {
            &:after {
                content: 'EXE'
            }
            &:before {
                background-image: url({{file_dest}}/icon-exe.svg)
            }
        }
        &[rel="external"] {
            &:before {
                content: '';
                display: inline-block;
                background-repeat: no-repeat;
                margin-right: pxToRem(4);
                background-image: url({{file_dest}}/icon-external.svg);
                height: pxToRem(14);
                width: pxToRem(14);
                vertical-align: middle;
                margin-top: pxToRem(-4);
            }
        }
    }
    #main ul & {
        /*
        &[rel="external"],
        &[href$=".doc"],
        &[href$=".docx"],
        &[href$=".pdf"],
        &[href$=".rtf"],
        &[href$=".exe"] {
            &:after {
                content: none
            }
            &:before {
                content: '';
                display: inline-block;
                background-repeat: no-repeat;
                margin-right: pxToRem(6);
            }
        }
        &[href$=".doc"],
        &[href$=".docx"],
        &[href$=".pdf"],
        &[href$=".rtf"],
        &[href$=".exe"] {
            //color: #363636;
            // font-size: pxToRem(12);
            // font-family: "Montserrat", Helvetica, Arial, Sans-serif;
            // line-height: 1.25;
            // display: -webkit-inline-box;
            // display: -ms-inline-flexbox;
            // display: inline-flex;
            // -webkit-box-align: center;
            // -ms-flex-align: center;
            // align-items: center;
            // vertical-align: middle;
            &:hover,
            &:focus {
                font-weight: 600;
                text-decoration: underline;
            }
            &:before {
                height: pxToRem(16);
                width: pxToRem(16);
                background-size: contain;
                margin-right: pxToRem(5);
                margin-left: 2px;
            }
        }*/
    }
}

// IE10 Metro responsive
// Required for Windows 8 Metro split-screen snapping with IE10
// Source: http://timkadlec.com/2012/10/ie10-snap-mode-and-responsive-design/
@-ms-viewport {
    width: device-width;
}

// Print style that shouldn't belong to print.scss to avoid the css beautifier from killing
// the @ syntax inside media queries
@page {
    margin: 0.5cm;
}


/*
--------------------
Content
--------------------
*/

// You can wrap the content styles in a class by un-commenting the following lines
// to nest the content.scss styles inside .content
#main {
    @import "content.scss";
    /* Quotes */
    blockquote {
        font-size: pxToRem(15);
        line-height: 1.5;
        padding-left: pxToRem(17.5);
        margin: 2em 0 2rem 1.5rem;
        border-left: 2px solid #00aeef;
    }
}


/*
--------------------
Modules
--------------------
*/