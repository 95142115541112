// Variables

$accordion-border-color:          #ccc;
$accordion-border-style:          solid;
$accordion-border-width:          pxToRem(1);
$accordion-border:                $accordion-border-width $accordion-border-style $accordion-border-color;
$accordion-border-y:              none;
$accordion-border-x:              none;
$accordion-color:                 $color-primary;
$accordion-item-height:           pxToRem(50);
$accordion-item-height-desktop:   pxToRem(72);
$accordion-padding-default:       pxToRem(15);
$accordion-panel-padding:         pxToRem(20) pxToRem(15);

$accordion-panel-bg-color:        transparent;
$accordion-title-bg-color:        #d9d9d9;

$accordion-title-color:           #363636;
$accordion-active-title-bg-color: #bfbfbf;
$accordion-active-title-color:    $accordion-title-color;
$accordion-title-hover-bg-color:  darken($accordion-title-bg-color, 2%);

$accordion-tab-color:             $accordion-title-color;
$accordion-active-tab-color:      $accordion-active-title-color;
$accordion-tab-bg-color:          $accordion-title-bg-color;
$accordion-active-tab-bg-color:   $accordion-active-title-bg-color;
$accordion-tab-hover-bg-color:    darken($accordion-tab-bg-color, 4%);

$accordion-tab-margin:            pxToRem(10);
$accordion-tab-opacity:           1;
$accordion-tab-width:             auto; //100%;

$accordion-title-font-size:       1;
$accordion-font-size:             1;
$accordion-open-transition:       $default-transition;
$accordion-ui-transition:         $fast-transition;
$accordion-panel-transition:      $default-transition;
