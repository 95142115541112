#main {
    blockquote {
        width: 100%;
        max-width: pxToRem(735);
        margin: pxToRem(38) 0 pxToRem(38) pxToRem(85);
        border-left-width: 5px;
        padding-left: pxToRem(45);
    }
}

img,
figure {
    &.pull-left {
        margin-right: 5rem;
        max-width: none;
    }
    &.pull-right {
        margin-left: 5rem;
        max-width: none;
    }
}

/*
--------------------
Modules
--------------------
*/
