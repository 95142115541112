/* Module: S */
.footer {
    body.inner__page & {
        margin-top: 4.5rem;
    }
}

.footer__section {
    padding: pxToRem(28) pxToRem(34);

    > .page__wrapper {
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }
}

.social__list {
    margin-bottom: 0;
}

.links__item {
    padding-right: pxToRem(15);
    margin-right: pxToRem(15);
    margin-bottom: 0;
}

.footer-top {
    .links__list {
        margin-left: pxToRem(30);
        margin-bottom: 0
    }

    > .page__wrapper {
        -ms-flex-align: center;
        align-items: center;
    }
}

.footer-bottom {
    @media screen and (max-width: 59.9375em) {
        .links__list {
            display: block;
            -webkit-column-count: 2;
            -moz-column-count: 2;
            column-count: 2;
        }

        .links__item {
            padding-right: 0;
            margin-right: 0;
            margin-bottom: pxToRem(6);
            border-right: 0;
        }
    }

    .footer__link {
        font-size: pxToRem(16);
        color: $tablet_footer-link--color;
    }
}

.page__update {
    font-size: pxToRem(13);
    color: $tablet_footer-link--color;
    margin-top: pxToRem(40);
    margin-bottom: 0;
}

.logo__item {
    border-right: 0;
    margin-left: pxToRem(30);
}

.logo__icon {
    height: pxToRem(50);

    &.logo-canberra { width: pxToRem(82) }
    &.logo-your-say { width: pxToRem(70) }
    &.logo-act { width: pxToRem(90) }
}
