/* Module: S */
.proposal__container {
    padding-top: pxToRem(29);

    > .page__wrapper {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }
}

.proposal__tile {
    display: -ms-flexbox;
    display: flex;
    width: calc(50% - 7.5px);
    &:nth-last-child(2) { margin-bottom: 0 }
}

.proposal__link {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    flex: 1;
    padding: pxToRem(20) pxToRem(35) 0 pxToRem(5);

    .three-cols & {
        padding-top: pxToRem(30);
    }
}

.proposal__heading {
    font-size: pxToRem(23);
}

.proposal__description {
    font-size: pxToRem(18);
    -ms-flex-positive: 1;
    flex-grow: 1;
    margin-top: pxToRem(11);
}

.proposal__icon {
    position: static;
    margin-top: pxToRem(15);
}

.proposal__title {
    color: #65676e;
    font-size: pxToRem(26);
    text-align: center;
    padding-top: pxToRem(30);
    margin-bottom: pxToRem(55);
    border-top-color: transparent;
}
