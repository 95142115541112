/* Module: S */
.article-navigation {
    max-height: none;
    border-bottom: 1px solid #008bbf;
    padding-bottom: pxToRem(35);
    margin-bottom: pxToRem(45);
}

.article-navigation__toggler {
    display: none;
}

.article-navigation__list {
    -moz-columns: 2;
    -webkit-columns: 2;
    columns: 2;
    background-color: transparent;
    padding: 0;
}

.article-navigation__item {
    border-bottom: 0;
    counter-increment: step-counter;
}

.article-navigation__link {
    font-size: pxToRem(16);
    font-weight: 600;
    display: inline-block;
    border-bottom: 1px solid transparent;
    padding-top: 0;
    padding-bottom: pxToRem(4);
    margin-bottom: pxToRem(15);
    @include sq-transition(.1s border-bottom-color ease-in-out);

    &:before {
        content: counter(step-counter) ". ";
    }

    &:hover,
    &:focus {
        border-bottom-color: currentColor;
        text-decoration: none;
    }

    &.active {
        color: #008bbf;
        border-bottom-color: #008bbf;
        text-decoration: none;
    }
}

.article-pagination {
    margin-top: 4.5rem;
}

.article-pagination__list {
    margin-left: pxToRem(-10);
    margin-right: pxToRem(-10);
}

.article-pagination__indicator {
    display: none;
}

.article-pagination__title {
    position: relative;
    display: block;
    color: #181818;
    font-size: pxToRem(16);
    font-family: "Montserrat", Helvetica, Arial, Sans-serif;

    &:before {
        display: block;
        font-size: pxToRem(20);
        font-weight: 600;
    }

    &:after {
        content: '';
        position: absolute;
        top: 50%;
        margin-top: pxToRem(-16);
        background-image: url('data:image/svg+xml;charset=utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDMyLjYzNSAzMi42MzUiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDMyLjYzNSAzMi42MzU7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iMjRweCIgaGVpZ2h0PSIyNHB4Ij4KPGc+Cgk8cGF0aCBkPSJNMzIuMTM1LDE2LjgxN0gwLjVjLTAuMjc2LDAtMC41LTAuMjI0LTAuNS0wLjVzMC4yMjQtMC41LDAuNS0wLjVoMzEuNjM1YzAuMjc2LDAsMC41LDAuMjI0LDAuNSwwLjUgICBTMzIuNDExLDE2LjgxNywzMi4xMzUsMTYuODE3eiIgZmlsbD0iIzAwYWVlZiIvPgoJPHBhdGggZD0iTTE5LjU5OCwyOS4zNTNjLTAuMTI4LDAtMC4yNTYtMC4wNDktMC4zNTQtMC4xNDZjLTAuMTk1LTAuMTk1LTAuMTk1LTAuNTEyLDAtMC43MDdsMTIuMTg0LTEyLjE4NEwxOS4yNDQsNC4xMzYgICBjLTAuMTk1LTAuMTk1LTAuMTk1LTAuNTEyLDAtMC43MDdzMC41MTItMC4xOTUsMC43MDcsMGwxMi41MzcsMTIuNTMzYzAuMDk0LDAuMDk0LDAuMTQ2LDAuMjIxLDAuMTQ2LDAuMzU0ICAgcy0wLjA1MywwLjI2LTAuMTQ2LDAuMzU0TDE5Ljk1MSwyOS4yMDZDMTkuODU0LDI5LjMwNCwxOS43MjYsMjkuMzUzLDE5LjU5OCwyOS4zNTN6IiBmaWxsPSIjMDBhZWVmIi8+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==');
        background-repeat: no-repeat;
        background-position: center right;
        background-size: 32px;
        height: pxToRem(32);
        width: pxToRem(32);
    }

    .article-pagination__previous & {
        padding-left: pxToRem(60);
        &:before { content: 'Previous' }
        &:after {
            left: 0;
            @include sq-transform(rotate(180deg));
        }
    }

    .article-pagination__next & {
        padding-right: pxToRem(60);
        &:before {
            content: 'Next';
            text-align: right;
        }
        &:after { right: 0 }
    }
}

.article-pagination__link {
    padding: pxToRem(5) pxToRem(15);
    @include sq-transition(.15s background-color ease-in-out);
    
    &:hover,
    &:focus {
        outline: 0;
        text-decoration: none;
        background-color: rgba(0, 0, 0, 0.065);
        border-radius: pxToRem(4);
    }
}
