/* Module: S */
// Single line comments will be removed by the CSS preprocessor
// Hint: go nuts commenting your Sass
// Multiline comments will be preserved, use them when you need to send comments
// back out to the resulting .css file.
// Tip: Most boilerplate modules follow guidelines set out in http://bem.info/ as a rule of 
// thumb. You don't have to, but it helps with consistency and any class name collisions
// with other styles. Example naming convention:
// 
// .link-listing__element { ... }
// .link-listing__element--modifier { ... }
// .link-listing__sub-element { ... }
.link-listing {
    // Your styles here
    &__heading {
        position: relative;
        min-height: 200px;
        h1 {
            position: absolute;
            bottom: 0;
            margin: 0 0;
            padding: 20px 40px;
            color: #fff;
            font-weight: normal;
        }
        h1.red {
            background: #da1e26;
        }
        h1.purple {
            background: #ae3f9a;
        }
        h1.green {
            background: #66b6ab;
        }
        h1.blue {
            background: #272274;
        }
    }
}

.link-listing__banner {
    background-size: cover;
    background-position: center center;
    margin-bottom: 30px;
    margin-top: -40px;
    .page__wrapper {
        padding-left: 0px;
        padding-right: 2.5rem;
    }
}