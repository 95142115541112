/* Module: S */
.ques-css-js {
    text-align: center;
    @include sq-transition(.45s min-height ease-in-out, .45s max-height ease-in-out);
}

.ques-css-js__wrapper {
    overflow: hidden;
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    background: #fff;
    min-height: 0;
    max-height: 0;
    padding: 0px 30px;
    // margin-bottom:65px;
    @include sq-transition(.45s min-height ease-in-out, .45s max-height ease-in-out);
}

.ques-css-js__close {
    position: absolute;
    top: 35px;
    right: 35px;
    width: 18px;
    height: 18px;
    border: 0;
    background: transparent url('{{file_dest}}/cross-remove-sign.svg') center /cover no-repeat;
}

.ques-css-js__next-back {
    position: absolute;
    bottom: -100px;
    right: 32px;
    width: 100px;
    height: 32px;
    line-height: 32px;
    background: transparent;
    font-family: 'Source Sans Pro', Helvetica, Arial, Sans-serif;
    font-weight: 600;
    font-size: 23px;
    padding: 0;
    margin: 0;
    @include smooth-font();
    border: 0;
    outline: 0;
    &:before {
        position: absolute;
        bottom: -3px;
        height: 3px;
        width: 0;
        content: '';
        background: $blue-light1;
        @include ease-it(0.2);
    }
    &:focus:before {
        width: calc(100% - 53px);
    }
    &:after {
        position: absolute;
        right: -42px;
        width: 42px;
        height: 32px;
        top: 0;
        content: '';
        background: transparent url('{{file_dest}}/next.svg') center /32px no-repeat;
    }
    &--back {
        right: auto;
        left: 32px;
        display: none;
        &:after {
            left: -42px;
            right: auto;
            @include sq-rotate(180);
        }
    }
    &--exit {
        display: none;
        width: 140px;
        &:focus:before {
            width: calc(100% - 39px);
        }
        &:after {
            right: -32px;
            width: 32px;
            height: 32px;
            background: transparent url('{{file_dest}}/x.svg') center 8px /20px no-repeat;
        }
    }
}

.ques-css-js__content {
    margin: 90px auto 120px auto;
    max-width: 1000px;
    position: relative;
    padding-left: 60px;
    opacity: 0;
    visibility: hidden;
    @include sq-transition(.45s opacity ease-in-out,
    .45s visibility ease-in-out);
    .active & {
        opacity: 1;
        visibility: visible;
    }
    &:before {
        //background on mobile
        position: absolute;
        left: -20px;
        top: 0;
        content: " ";
        width: 60px;
        height: 100%;
        background: white;
        z-index: 2;
    }
    //remove progress bar padding on questioners
    &--ques {
        padding-left: 0;
        &:before {
            width: 0;
            height: 0;
        }
    }
}

.ques-css-js__content__progress-bar {
    width: 300px;
    height: 6px;
    border: 1px solid $grey-dark1;
    @include sq-border-radius(4px);
    // @include sq-box-shadow(inset 0px -1px 1px 0px rgba(0,0,0,0.2));
    content: ' ';
    position: absolute;
    top: 0;
    left: 20px;
    margin: 0;
    z-index: 3;
    transform-origin: top left;
    @include sq-rotate(90);
    &--bottom {
        top: auto;
        bottom: 245px;
    }
    &--floating {
        position: fixed;
        left: 40px;
        top: 20px;
    }
}

.ques-css-js__content__progress-bar__progress {
    position: absolute;
    left: 0;
    top: 0;
    content: ' ';
    width: 25%;
    height: 50%;
    top: 25%;
    z-index: -1;
    background: $blue-light1;
}

.ques-css-js__content__progress-circle {
    width: 38px;
    line-height: 36px;
    height: 38px;
    top: -16px;
    left: 25%;
    margin-left: -19px;
    border: 1px solid $grey-dark1;
    @include sq-border-radius(50%);
    @include sq-rotate(-90);
    position: absolute;
    background: #fff;
    &:after {
        z-index: 1;
        height: 32px;
        width: 32px;
        @include sq-border-radius(50%);
        top: 2px;
        left: 2px;
        content: ' ';
        position: absolute;
        background: $grey-light1;
    }
    // &:before{
    //   position: absolute;
    //   height:4px;
    //   width:38px;
    //   left:-1px;
    //   background: white;
    //   content:' ';
    //   z-index: 0;
    //   top:15px;
    //   @include sq-rotate(-90);
    // }
    &>span {
        position: relative;
        z-index: 2;
        font-family: 'Montserrat-Bold', sans-serif;
        font-size: 18px;
    }
    &--2of2 {
        left: 75%;
    }
    &--1of2 {
        left: 25%;
    }
    &--current {
        &:after {
            background: $blue-light1;
        }
        &>span {
            color: #fff;
            @include smooth-font();
        }
    }
    &--done {
        &:after {
            background: $blue-light1 url('{{file_dest}}/fill-1.svg') center /16px no-repeat;
        }
        &>span {
            font-size: 0;
        }
    }
}

.ques-css-js__content__body {
    text-align: left;
    position: relative;
    display: none;
    margin-left: 0;
    width: 100%;
    min-height: 250px;
    z-index: 1;
    &[this-Step="1"] {
        display: block;
    }
    &--results {
        font-family: 'Source Sans Pro', Helvetica, Arial, Sans-serif;
        font-weight: 600;
        @include smooth-font();
    }
}

.ques-css-js__content__question {
    margin: 30px 0 20px 0;
    font-family: 'Source Sans Pro', Helvetica, Arial, Sans-serif;
    font-weight: 600;
    @include smooth-font();
    letter-spacing: -0.4px;
    display: block;
}

.result-cell {
    display: inline-block;
    vertical-align: top;
    min-width: 49%;
    margin-bottom: 20px;
}

.result-list {
    margin: 0 0 0 20px;
    padding: 0;
    list-style: none;
    font-family: 'Source Sans Pro', Helvetica, Arial, Sans-serif;
    font-weight: 400;
    &>li {
        position: relative;
        line-height: 30px;
        &:after {
            display: none;
            position: absolute;
            content: " ";
            width: 20px;
            height: 5px;
            background: url('{{file_dest}}/circle.png') center left/5px no-repeat;
            top: 14px;
            left: -20px;
        }
    }
}

.result-title {
    font-size: 20px;
    font-family: 'Source Sans Pro', Helvetica, Arial, Sans-serif;
    font-weight: 600;
    margin-bottom: 20px;
    &--total {
        font-size: 26px;
    }
    &--child {
        margin-bottom: 0;
        font-family: 'Source Sans Pro', Helvetica, Arial, Sans-serif;
        font-weight: 400;
    }
    &--total-title {
        margin-top: 30px;
        margin-bottom: 0;
        font-size: 24px;
    }
    &--total-title-small {
        margin: 20px 0 0 0;
        font-size: 22px;
    }
}

.results-seperator {
    height: 1px;
    width: 90%;
    margin: 30px 0;
    background: $blue-light1;
}

.result-amount {
    font-size: 42px;
    margin-bottom: 20px;
    &--multi {
        font-size: 22px;
        margin-bottom: 10px;
    }
    &--total {
        font-size: 24px;
    }
}

.on-off-ques-calc {
    display: none;
}