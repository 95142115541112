/* Module: S */
.search-query__container {
    max-width: 75%;
}

.search-result__heading {
    max-width: calc(100% - 100px);
    font-size: pxToRem(28);
}

.search-result__item {
    margin-bottom: pxToRem(25);
}

.search-result__date {
    position: absolute;
    top: pxToRem(15);
    right: pxToRem(15);
    margin-bottom: 0;
    &:before { content: none }
}
