/* Module: Pagination */
.pagination {
    @extend %list-reset;
    font-family: "Montserrat", Helvetica, Arial, Sans-serif;
    font-weight: 400;
    margin-top: 2.5rem;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.pagination__item {
    display: block;
    margin-right: pxToRem(10);
    border-bottom: 1px solid transparent;
    &:last-of-type { margin-right: 0 }
}

.pagination__link,
.pagination__item > span {
    display: block;
    color: $base-font-colour;
    font-size: pxToRem(15);
    line-height: 1.45;
    text-decoration: none;
    padding: pxToRem(4) pxToRem(8);

    &:hover,
    &:focus {
        text-decoration: none;
    }
}

.pagination__item:not(.pagination__next, .pagination__previous) {
    &:hover,
    &:focus {
        border-bottom-color: $base-font-colour;
    }
}

.pagination__previous,
.pagination__next {
    > span,
    .pagination__link {
        font-size: pxToRem(18);
        line-height: pxToRem(21);
    }

    > span {
        cursor: default;
        opacity: .35;
    }
}

.pagination__item--current,
.pagination__item--current,
.pagination__item--current {
    cursor: default;
    border-bottom-color: #00aeef !important;
    > span:not(.visuallyhidden) {
        color: #00aeef;
    }
}
