/* Module: S */
.header__inner {
    > .page__wrapper {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        max-width: none;
        padding: 0;
    }
}

.header__navigation {
    background-color: #343434;
    min-height: pxToRem(40);
    -ms-flex-order: -1;
    order: -1;

    > .page__wrapper {
        padding-left: 0;
        padding-right: 0;
    }
}

.header__icon {
    display: block;
    fill: #fff;
    height: pxToRem(17);
    width: pxToRem(17);
}

.header__list {
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.header__item {
    border-right: 1px solid darken(#fff, 35%);

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        display: -ms-flexbox;
        display: flex;
    }

    &.header-search__item {
        -ms-flex-positive: 1;
        flex-grow: 1;
    }

    &:last-of-type { border-right: 0 }
}

.header__link {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    color: #fff;
    font-size: pxToRem(10);
    font-weight: 300;
    text-transform: uppercase;
    text-decoration: none;
    line-height: 1;
    min-height: pxToRem(40);
    padding-left: pxToRem(15);
    padding-right: pxToRem(15);
    @include sq-transition(.15s background-color ease-in-out, .15s color ease-in-out);

    &:hover,
    &:focus {
        background-color: darken(#343434, 4%);
        text-decoration: none;
    }
}

.header__search {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: end;
    justify-content: flex-end;
    position: relative;
    min-height: pxToRem(40);
    width: 100%;
    &:focus { outline: none }
}

.header-search__input {
    display: none;
    font-size: pxToRem(14);
    line-height: 1.5;
    background-color: #fff;
    border: 0;
    padding: 0 pxToRem(50) 0 pxToRem(15);
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    .active > & { display: block }
    &:focus { outline: 0 }
}

.header-search__button {
    background-color: transparent;
    padding: 0 pxToRem(20);
    border: 0;
    position: relative;
    z-index: 2;
}

.header-search__icon {
    pointer-events: none;
    stroke: #fff;

    .active & {
        fill: #343434;
        stroke: #343434;
    }
}

.logo__container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    overflow: hidden;
    padding-top: pxToRem(16);
    padding-bottom: pxToRem(16);

    body.inner__page & {
        background-color: #cdcdcd;
        padding-top: pxToRem(18);
        padding-bottom: pxToRem(18);
    }
}

.logo__image {
    display: block;
    fill: #fff;
    width: pxToRem(220);
    height: pxToRem(34);

    body.inner__page & {
        fill: #000;
    }
}

.header-scroll__button {
    display: none;
    color: #000;
    position: absolute;
    left: 50%;
    bottom: pxToRem(-26);
    margin-left: pxToRem(-26);
    background-color: #9b9b9b;
    height: pxToRem(52);
    width: pxToRem(52);
    border-radius: 50%;
    @include sq-transition(.15s background-color ease-in-out);

    &:hover,
    &:focus {
        background-color: darken(#9b9b9b, 10%);
    }

    &:before {
        content: '';
        display: block;
        height: pxToRem(14);
        width: pxToRem(14);
        border-right: 3px solid #fff;
        border-bottom: 3px solid #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -10px;
        margin-left: -7px;
        @include sq-transform(rotate(45deg));
    }
}
