/* Module: S */
.contact__box {
    padding-top: pxToRem(35)
}

.contact__heading {
    margin-top: 0
}

.contact__content {
    border-top: 1px solid #979797;
    margin-top: pxToRem(35);
    padding-top: pxToRem(35);
    line-height: 1.75;
    div {
        margin-bottom: 20px;
        &:last-of-type {
            margin-bottom: 0
        }
    }
}

.sq-form-error.sq-captcha-error {
    display: block;
    padding: 10px 20px;
    border: 1px solid red;
    margin-bottom: 10px;
    color: red;
    text-align: center;
}