/* Module: Responsive tables */
#main {
    table {
        p { margin: 0 }
        
        &:not(.table-horizontal-heading) {
            border-top: 1px solid #00aeef;

            thead { display: none }

            tr {
                background-color: #e9e9e9;
                border-bottom: 1px solid #bbbbbb;

                &:nth-child(even) {
                    background-color: #fff;
                }
            }

            td {
                display: -ms-flexbox;
                display: flex;
                border-bottom: 0;
                margin: pxToRem(15) pxToRem(15) 0 pxToRem(15);
                font-size: pxToRem(15);

                &:last-of-type { margin-bottom: pxToRem(15) }

                &:before {
                    content: attr(data-th);
                    font-weight: 600;
                    display: inline-block;
                    min-width: pxToRem(100);
                    margin-right: pxToRem(10);
                }
            }
        }

        &.table-horizontal-heading {
            min-width: pxToRem(750);

            th, td {
                font-size: 1rem;
                border-bottom: 1px solid #bbbbbb;
                &.active { background-color: #fff }
            }

            td {
                text-align: center;
                padding: pxToRem(15);

                &:first-of-type {
                    text-align: left;
                    font-weight: 600;
                    font-family: "Montserrat", Helvetica, Arial, Sans-serif;
                    padding: pxToRem(12) pxToRem(15);
                    background-color: #d8d8d8;
                    border-right: 1px solid #00aeef;
                    &.active { background-color: #fff }
                }
            }

            th {
                text-align: center;
                padding: pxToRem(15);
                border-bottom: 1px solid #00aeef;

                &:first-of-type {
                    text-align: left;
                    font-weight: 600;
                    font-family: "Montserrat", Helvetica, Arial, Sans-serif;
                    border-right: 1px solid #00aeef
                }
            }
        }
    }

    .table-overflow {
        overflow: auto;

        table {
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    .table-indicator {
        position: relative;
        margin-top: pxToRem(25);
        margin-bottom: pxToRem(25);

        &:after,
        &:before {
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            width: pxToRem(50);
            background: -moz-linear-gradient(left, rgba(0,0,0,0) 0%, rgba(0,0,0,0.15) 75%, rgba(0,0,0,.25) 100%);
            background: -webkit-linear-gradient(left, rgba(0,0,0,0) 0%, rgba(0,0,0,0.15) 75%, rgba(0,0,0,.25) 100%);
            background: linear-gradient(to right, rgba(0,0,0,0) 0%, rgba(0,0,0,0.15) 75%, rgba(0,0,0,.25) 100%);
            border-right: 1px solid #999;
            opacity: 0;
            visibility: hidden;
            @include sq-transition(.2s all ease-in-out);
        }

        &:before {
            border-right: 0;
            border-left: 1px solid #999;
            background: -moz-linear-gradient(right, rgba(0,0,0,0) 0%, rgba(0,0,0,0.15) 75%, rgba(0,0,0,.25) 100%);
            background: -webkit-linear-gradient(right, rgba(0,0,0,0) 0%, rgba(0,0,0,0.15) 75%, rgba(0,0,0,.25) 100%);
            background: linear-gradient(to left, rgba(0,0,0,0) 0%, rgba(0,0,0,0.15) 75%, rgba(0,0,0,.25) 100%);
            right: auto;
            left: 0;
        }

        &.show-indicator-left {
            &:before {
                opacity: 1;
                visibility: visible;
            }
        }

        &.show-indicator-right {
            &:after {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}
