/* Module: S */
.header {
    background-color: #000;
    position: relative;
    &:before {
        content: '';
        position: absolute;
        z-index: 2;
        top: pxToRem(40);
        left: 0;
        right: 0;
        height: pxToRem(175);
        background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.35) 0%, transparent 100%);
        background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.35) 0%, transparent 100%);
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.35) 0%, transparent 100%);
    }
    body.inner__page & {
        background-color: transparent;
        &:before {
            content: none
        }
    }
}

.header__background {
    background-color: #000;
    background-size: cover;
    background-position: center center;
    &,
    &:before,
    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
    &:after {
        background-color: #3c2b76;
        opacity: 0.55;
    }
}

.header__inner {
    position: relative;
    z-index: 5;
    body.inner__page & {
        margin-bottom: pxToRem(40);
    }
}

.header__categories {
    position: relative;
    z-index: 5;
    padding-bottom: pxToRem(55);
}

.category__list,
.sub-category__list {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: justify;
    justify-content: space-between;
    overflow: hidden;
    position: relative;
}

.category__item,
.sub-category__item {
    min-height: 0;
    width: calc(50% - 2px);
    @include sq-transition(.3s min-height ease-in-out);
    &.full-width {
        width: 100%
    }
    @media screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column
    }
}

.category__link,
.sub-category__link {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    font-size: pxToRem(12);
    color: #000;
    text-decoration: none;
    line-height: 1.25;
    border-left: pxToRem(5) solid #00aeef;
    min-height: pxToRem(52);
    margin-bottom: pxToRem(4);
    padding-left: pxToRem(12);
    padding-right: pxToRem(12);
    @include sq-transition( .25s background-color ease-in-out, .25s background-image ease-in-out, .25s background-position ease-in-out);
    &:hover,
    &:focus,
    .active>& {
        color: #343434;
        text-decoration: none;
        .category__icon {
            color: #343434
        }
    }
}

.sub-category__link {}

.category__link {
    background-color: rgba(255, 255, 255, .75);
    background-image: -webkit-gradient(linear, left top, right top, color-stop(50%, #00aeef), color-stop(50%, transparent));
    background-image: -ms-linear-gradient(right, #00aeef 50%, transparent 50%);
    background-image: linear-gradient(to right, #00aeef 50%, transparent 50%);
    background-position: calc(100% - -1px) 0;
    background-size: 205% 200%;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        background-position: 100% 0;
    }
    &:hover,
    &:focus {
        background-position: 0 0;
    }
    .active>& {
        background-color: #00aeef;
    }
}

.category__icon {
    color: #9A9A9A;
    font-size: pxToRem(20);
    margin-right: pxToRem(15);
    pointer-events: none;
    @include sq-transition(.15s color ease-in-out);
    &.fa-home {
        font-size: pxToRem(24)
    }
}

.sub-category__list {
    display: none;
    position: absolute;
    left: 0;
    z-index: 2;
    .active>& {
        position: relative;
        display: -ms-flexbox;
        display: flex;
    }
}

.sub-category__link {
    color: #343434;
    background-color: #00aeef;
    background-image: url('data:image/svg+xml;charset=utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDMyLjYzNSAzMi42MzUiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDMyLjYzNSAzMi42MzU7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4Ij4KPGc+Cgk8cGF0aCBkPSJNMzIuMTM1LDE2LjgxN0gwLjVjLTAuMjc2LDAtMC41LTAuMjI0LTAuNS0wLjVzMC4yMjQtMC41LDAuNS0wLjVoMzEuNjM1YzAuMjc2LDAsMC41LDAuMjI0LDAuNSwwLjUgICBTMzIuNDExLDE2LjgxNywzMi4xMzUsMTYuODE3eiIgZmlsbD0iIzM0MzQzNCIvPgoJPHBhdGggZD0iTTE5LjU5OCwyOS4zNTNjLTAuMTI4LDAtMC4yNTYtMC4wNDktMC4zNTQtMC4xNDZjLTAuMTk1LTAuMTk1LTAuMTk1LTAuNTEyLDAtMC43MDdsMTIuMTg0LTEyLjE4NEwxOS4yNDQsNC4xMzYgICBjLTAuMTk1LTAuMTk1LTAuMTk1LTAuNTEyLDAtMC43MDdzMC41MTItMC4xOTUsMC43MDcsMGwxMi41MzcsMTIuNTMzYzAuMDk0LDAuMDk0LDAuMTQ2LDAuMjIxLDAuMTQ2LDAuMzU0ICAgcy0wLjA1MywwLjI2LTAuMTQ2LDAuMzU0TDE5Ljk1MSwyOS4yMDZDMTkuODU0LDI5LjMwNCwxOS43MjYsMjkuMzUzLDE5LjU5OCwyOS4zNTN6IiBmaWxsPSIjMzQzNDM0Ii8+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==');
    background-repeat: no-repeat;
    background-position: center right 15px;
    padding-right: pxToRem(35);
    @include sq-transition(.15s all ease-in-out);
    &:hover,
    &:focus {
        color: #000;
        background-color: darken(#00aeef, 4%);
        border-left-color: darken(#00aeef, 4%);
    }
}