/* Module: S */
.search-page-funnelback {
    // Your styles here
    #search-facets {
        float: left;
        width: 30%;
    }
    #search-inner-wrapper {
        float: right;
        width: 68%;
    }
}