/* Module: S */
.header {
    min-height: pxToRem(680);

    &:before {
        top: 0
    }

    body.inner__page & {
        min-height: auto;
    }
}

.header__background {
    &:before {
        background-position: top 49% right 41%;
    }
}

.header__categories {
    padding-bottom: pxToRem(35);
}

.category__link,
.sub-category__link {
    margin-right: pxToRem(8);
    margin-bottom: pxToRem(8);
}
