/* Module: S */
.header__navigation {
    min-height: pxToRem(60);
}

.header__search {
    max-width: pxToRem(295);
    border: 1px solid #7c7c7c;
    border-radius: pxToRem(30);
    margin: pxToRem(10);
    @include sq-transition(.15s border-color ease-in-out, .15s background-color ease-in-out);

    &.active {
        border-color: #fff;
        background-color: #fff;
    }
}

.header__link {
    font-size: pxToRem(13);
    min-height: pxToRem(60);
    padding-left: pxToRem(30);
    padding-right: pxToRem(30);

    .header-contact__item & {
        padding-left: pxToRem(20);
        padding-right: pxToRem(20);
    }
}

.header__icon {
    height: pxToRem(21);
    width: pxToRem(21);
}

.header-search__input {
    display: block;
    color: #fff;
    font-size: pxToRem(13);
    background-color: transparent;
    position: static;
    height: auto;
    padding-left: pxToRem(20);
    padding-right: 0;

    &::-webkit-input-placeholder { color: #dbdbdb }
    &::-moz-placeholder { color: #dbdbdb }
    &:-ms-input-placeholder { color: #dbdbdb }

    .active > & {
        color: #343434;

        &::-webkit-input-placeholder { color: lighten(#343434, 10%) }
        &::-moz-placeholder { color: lighten(#343434, 10%) }
        &:-ms-input-placeholder { color: lighten(#343434, 10%) }
    }
}

.header-search__icon {
    height: pxToRem(19);
    width: pxToRem(19);
}

.logo__container {
    padding-top: pxToRem(45);
    padding-bottom: pxToRem(45);

    body.inner__page & {
        background-color: transparent;
        padding-top: pxToRem(25);
        padding-bottom: 0;
    }
}

.logo__image {
    width: pxToRem(365);
    height: pxToRem(60);
}
