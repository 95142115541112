/* Module: S */
.questionnaires__list {
    @extend %list-reset;
}

.questionnaires__list.questionnaires__list-links {
    margin-top: 50px;
}

.questionnaires__item {
    margin-bottom: 1rem;
    min-height: 0;
    @include sq-transition(.35s min-height ease-in-out);
    &:last-of-type {
        // margin-bottom: 0
    }
}

.questionnaires__link {
    position: relative;
    color: $base-font-colour;
    font-size: pxToRem(17);
    font-family: "Montserrat", Helvetica, Arial, Sans-serif;
    font-weight: 600;
    line-height: 1.35;
    border: 1px solid #00aeef;
    display: block;
    text-decoration: none;
    padding: pxToRem(20) pxToRem(45) pxToRem(20) pxToRem(20);
    @include sq-transition(.125s background-color linear, .125s color linear);
    &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: pxToRem(20);
        display: block;
        height: pxToRem(16);
        width: pxToRem(16);
        border-right: 2px solid $base-font-colour;
        border-bottom: 2px solid $base-font-colour;
        @include sq-transition(.125s border-color linear, .125s transform linear);
        @include sq-transform(translateY(-50%) rotate(-45deg));
        .active>& {
            @include sq-transform(translateY(-50%) rotate(45deg));
            margin-top: pxToRem(-4);
        }
    }
    &:hover,
    &:focus,
    &.active,
    .active>& {
        color: #fff;
        text-decoration: none;
        background-color: #00aeef;
        &:after {
            border-right-color: #fff;
            border-bottom-color: #fff;
        }
    }
    &.disabled {
        pointer-events: none;
    }
}

.questionnaires__description {
    font-size: pxToRem(14);
    font-weight: 300;
    margin-top: pxToRem(8);
    line-height: 1.45;
}

.questionnaires__desc {
    font-weight: normal;
    font-size: 16px;
}

.questionnaires__content {
    position: absolute;
    left: 0;
    right: 0;
    overflow: hidden;
    background-color: #fff;
    margin-top: pxToRem(40);
    margin-bottom: pxToRem(40);
    opacity: 0;
    visibility: hidden;
    @include sq-transition(.2s all ease-in-out);
    .active>& {
        visibility: visible;
        opacity: 1;
    }
    >.page__wrapper {
        padding-top: pxToRem(20);
        padding-bottom: pxToRem(20);
    }
    p {
        &:last-of-type {
            margin-bottom: 0
        }
    }
}

.sq_question_wrapper .fancy-radio li:first-child:nth-last-child(4),
.sq_question_wrapper .fancy-radio li:first-child:nth-last-child(4)~li {
    width: 100%;
    max-width: 100%;
}