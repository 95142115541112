/* Module: S */
// Single line comments will be removed by the CSS preprocessor
// Hint: go nuts commenting your Sass
// Multiline comments will be preserved, use them when you need to send comments
// back out to the resulting .css file.
// Tip: Most boilerplate modules follow guidelines set out in http://bem.info/ as a rule of 
// thumb. You don't have to, but it helps with consistency and any class name collisions
// with other styles. Example naming convention:
// 
// .search-page-funnelback__element { ... }
// .search-page-funnelback__element--modifier { ... }
// .search-page-funnelback__sub-element { ... }
.search-page-funnelback {
    // Your styles here
    #query {
        display: inline-block;
        height: 3.4775rem;
        margin: 0px;
        padding-left: 20px;
        padding-right: 20px;
    }
    #searchform input[type=submit] {
        display: inline-block;
        margin-left: -1px;
        line-height: 1.35;
    }
    #searchform>div {
        display: flex;
    }
    #searchform {
        input[type=checkbox],
        label {
            display: none;
        }
    }
    #search-matching {
        margin-top: 20px;
        margin-top: 20px;
        background: #ddd;
        padding: 10px 20px;
        strong {
            font-style: italic;
        }
    }
    #search-results {
        list-style: none;
        list-style-type: none;
        margin: 0px 0px !important;
        padding: 0px 0px !important;
        
        .search-title {
            margin-top: 0px;
            font-size: 20px;
        }
        
        a:before {
            display: none;
        }
        li {
            margin-left: 0px;
            padding-left: 0px;
            border: 1px solid #0076a2;
            padding: 30px !important;
            overflow: hidden;
            margin-bottom: 20px !important;
            h3 {
                margin-top: 0px;
                margin-bottom: 10px;
                font-weight: 100 !important;
            }
            .search-file-format {
                margin-bottom: 0px;
                color: #6e6e6e;
                font-size: 14px;
            }
            cite {
                font-size: 14px;
                color: #6e6e6e;
                margin-bottom: 10px;
                display: block;
                white-space: pre-wrap;
                white-space: -moz-pre-wrap;
                white-space: -pre-wrap;
                white-space: -o-pre-wrap;
                word-wrap: break-word;
            }
            .search-explore {
                display: block;
                text-align: right;
                margin-bottom: 0px;
                a {
                    display: inline-block;
                }
            }
            .search-date,
            .search-summary {
                margin-top: 15px;
                white-space: pre-wrap;
                white-space: -moz-pre-wrap;
                white-space: -pre-wrap;
                white-space: -o-pre-wrap;
                word-wrap: break-word;
            }

            .search-result-thumbnail-wrapper,
            .search-quicklinks,
            .search-quicklinks-search {
                display: none;
            }
        }
        li:before {
            display: none;
        }
    }
    #search-facets {
        float: none;
        width: 100%;
        border: 1px solid #0076a2;
        .facet {
            padding-bottom: 20px;
            .facetLabel {
                font-family: "Montserrat", Helvetica, Arial, Sans-serif;
                font-weight: bold;
                font-size: 20px;
                // display: block;
                // background: #0076a2;
                // color: #fff;
                display: inline-block;
                padding: 10px 20px;
                // margin-bottom: 15px;
                padding-bottom: 0px;
                padding-right: 0px;
            }
            .category {
                padding: 0px 20px;
                .search-facet-count {
                    background: #767676;
                    font-size: 12px;
                    padding: 3px 5px;
                    border-radius: 3px;
                    color: #fff;
                    line-height: 12px;
                    display: inline-block;
                }
            }
        }
    }
    #search-inner-wrapper {
        float: none;
        width: 100%;
        .search-pagination ul {
            font-family: "Montserrat", Helvetica, Arial, Sans-serif;
            font-weight: 400;
            margin: 0;
            padding: 0;
            list-style: none;
            margin-top: 2.5rem;
            display: -ms-flexbox;
            display: flex;
            justify-content: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            li {
                display: block;
                list-style: none;
                margin-right: 5px;
                border-bottom: 1px solid transparent;
                padding: 0px 0px !important;
                a {
                    display: block;
                    color: #363636;
                    font-size: 0.9375rem;
                    line-height: 1.45;
                    text-decoration: none;
                    padding: 0.25rem 0.5rem;
                }
            }
            li:before {
                display: none !important;
            }
            li.active {
                cursor: default;
                border-bottom-color: #00aeef !important;
            }
            li:hover {
                border-bottom-color: #363636;
            }
        }
        #search-contextual-navigation {
            margin-bottom: 50px;
            h2 {
                font-size: 20px;
                margin-top: 30px;
                margin-bottom: 0px;
                strong {
                    font-style: italic;
                }
            }
            ul {
                margin-top: 0px;
                list-style: none;
                columns: 2;
                -webkit-columns: 2;
                -moz-columns: 2;
                li {
                    margin-bottom: 0px;
                    padding-left: 0px;
                    font-size: 16px;
                }
                li:before {
                    display: none;
                }
            }
        }
    }
    #search-advanced {
        margin-top: 20px;
        #search-advanced-toggle a {
            cursor: pointer;
        }
    }
    #advsearchform {
        display: none;
        fieldset {
            margin-bottom: 20px;
            legend {
                padding-left: 10px;
                padding-right: 10px;
                font-weight: bold;
                font-family: "Montserrat", Helvetica, Arial, sans-serif;
            }
            ul {
                margin: 0px 0px !important;
                margin-bottom: 10px !important;
                li {
                    margin-left: 0px;
                    padding-left: 10px !important;
                    padding-right: 10px;
                    label {
                        display: block;
                    }
                }
                li:before {
                    display: none !important;
                }
            }
        }
    }
    .col-3 {
        width: 32.7%;
        display: inline-block;
    }


    
}

.ui-autocomplete {
    max-width: 320px;
    list-style: none;
    padding-left: 20px;
    padding: 15px 20px;
    background: rgba(255, 255, 255, 0.99);
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    li a {
        cursor: pointer;
    }
}