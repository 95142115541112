/* Module: Responsive tables */
#main {
    table {
        &:not(.table-horizontal-heading) {
            td {
                font-size: pxToRem(17);
                margin: pxToRem(12.5) pxToRem(25);
                border-bottom: 1px solid #d9d9d9;
                padding-bottom: pxToRem(12.5);

                &:before {
                    font-size: pxToRem(17);
                    font-family: "Montserrat", Helvetica, Arial, Sans-serif;
                }

                &:first-of-type { margin-top: pxToRem(25) }
                &:last-of-type {
                    margin-bottom: pxToRem(25);
                    border-bottom: 0;
                    padding-bottom: 0;
                }
            }

            tr {
                border-bottom: 0;
                &:nth-child(even) {
                    td { border-bottom-color: #ebebeb }
                }
            }
        }
    }
}
