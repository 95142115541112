/* Module: S */
.tax-model__heading {
    padding-left: 0;
    padding-right: 0;
    font-size: pxToRem(30);
    line-height: 1.25;
}

.tax-model__title {
    font-size: pxToRem(15);
}
