/* Module: S */
.fancy-radio__option {
    width:50%;
}

.input-description {
    &--date-range {
        height:1px;
    }
}
