/* Module: S */
.ques-exit {
    display: block;
}

#questions {
    text-align: left;
    padding: 0;

    // hide questions to apply the animation (questions are appended by default, no animation is applied)
    .sq_question_wrapper:not(:first-child),
    .sq_result_bodycopy {
        display: none;
    }
}
