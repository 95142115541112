/* Module: Breadcrumbs */
.breadcrumbs {
    display: none;
    font-size: 1rem;
    margin-bottom: 2rem;
}

.breadcrumbs__list {
    @extend %list-reset;
}

.breadcrumbs__item {
    display: inline-block;
}

.breadcrumbs__link {
    color: $base-font-colour;
    text-decoration: none;
    &:hover,
    &:focus {
        text-decoration: underline;
    }
}

.breadcrumbs__item a[rel='external']::before {
    display: none !important;
}

.breadcrumbs__divider {
    @include sq-inline-block;
    margin: 0 1.5rem;
}

.breadcrumbs__current {
    font-weight: bold;
}