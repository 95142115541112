/* Module: S */
.tax-reform__section {
    margin-top: pxToRem(90);
    margin-bottom: pxToRem(90);
    padding-top: pxToRem(90);
    padding-bottom: pxToRem(90);

    > .page__wrapper {
        -ms-flex-direction: column;
        flex-direction: column;
    }
}

.tax-model {
    width: 100%;
    border-right: 0;
    padding: 0;
    &:last-of-type { padding-left: 0 }

    &:before {
        @include sq-transform(none);
    }

    &:after { content: none }

    &.current-model,
    &.new-model {
        &:before {
            top: auto;
            left: 0;
            bottom: pxToRem(-15);
        }
    }

    &.new-model {
        margin-top: pxToRem(90);
    }
}

.new-model {
    .tax-model__item {
        &:first-of-type { margin-right: pxToRem(34) }
        &:nth-child(2) { margin-right: pxToRem(58) }
        &:nth-child(2) { margin-right: pxToRem(58) }
        &:nth-child(5) { margin-right: pxToRem(42) }
    }
}

.tax-model__list {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;

    .new-model & {
        -ms-flex-pack: start;
        justify-content: flex-start;
    }
}

.tax-model__item {
    margin: 0 2rem 0 0;
    max-width: pxToRem(150);
    &:last-of-type { margin-right: 0 }
}

.tax-model__heading {
    min-height: auto;
    text-align: left;
    margin-bottom: pxToRem(55);
    padding-left: 0;
    padding-right: 0;
}

.tax-model__title {
    font-size: pxToRem(13);
}
