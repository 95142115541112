/* Module: S */
.header__inner {
    padding-top: pxToRem(40);
    margin-bottom: pxToRem(125);

    > .page__wrapper {
        max-width: 80rem;
        padding-left: 2.5rem;
        padding-right: 2.5rem;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }
}

.header__navigation {
    background-color: transparent;
    -ms-flex-order: 0;
    order: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
}

.logo__container,
.header__search {
    padding: 0;
}

.logo__container {
    body.inner__page & {
        padding-top: 0;
    }
}

.header__search {
    margin: 0 0 0 auto;
    background-color: #fff;
    border-color: #fff;

    body.inner__page & {
        border-color: #d2d2d2;

        &.active {
            border-color: darken(#d2d2d2, 15%);
        }
    }
}

.header-search__input {
    color: #343434;
    width: calc(100% - 57px);
    &::-webkit-input-placeholder { color: #414141 }
    &::-moz-placeholder { color: #414141 }
    &:-ms-input-placeholder { color: #414141 }
    &:-moz-placeholder { color: #414141 }
}

.header-search__icon {
    fill: #343434;
    stroke: #343434;
}

.header__item {
    border-right: 0;
    margin-left: pxToRem(27);
}

.header__link {
    min-height: pxToRem(40);
    border: 1px solid #fff;
    border-radius: pxToRem(30);

    &:hover,
    &:focus {
        color: #2a2a2a;
        background-color: #fff;
    }

    body.inner__page & {
        color: #343434;
        border-color: #343434;

        &:hover,
        &:focus {
            color: #fff;
            background-color: #343434;
        }
    }
}

.header-contact__item {
    -ms-flex-order: 3;
    order: 3;
    margin-left: pxToRem(16);

    .header__icon { display: none }
    .header__link {
        border: 0;
        padding-left: pxToRem(18);
        padding-right: pxToRem(18);
        &:after { content: 'Contact Us' }
    }
}

.header-scroll__button {
    display: block;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-perspective: 1000px;
    perspective: 1000px;

    &.shake {
        -webkit-animation: shake 1.75s cubic-bezier(.36, .07, .19, .97) 2 forwards;
        animation: shake 1.75s cubic-bezier(.36, .07, .19, .97) 2 forwards;
    }
}

@-webkit-keyframes shake {
  30%, 50%, 70% { -webkit-transform: translate3d(0, -4px, 0) }
  40%, 60%, 80% { -webkit-transform: translate3d(0, 4px, 0) }
}

@keyframes shake {
  30%, 50%, 70% { transform: translate3d(0, -4px, 0) }
  40%, 60%, 80% { transform: translate3d(0, 4px, 0) }
}
