/* Module: S */
.header {
    &:before {
        top: pxToRem(60);
    }
}

.header__background {
    &:before {
        // background-image: url(mysource_files/background-keyline.svg);
        background-repeat: no-repeat;
        background-size: 2050px;
        background-position: top 52% right 43%;
        opacity: 0.85;
        z-index: 2;
    }
}

.header__categories {
    padding-bottom: pxToRem(85);
}

.category__list,
.sub-category__list {
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.category__item,
.sub-category__item {
    width: auto;
    &.full-width {
        width: auto
    }
}

.category__link,
.sub-category__link {
    font-size: pxToRem(16);
    min-height: pxToRem(70);
    padding-left: pxToRem(20);
    padding-right: pxToRem(28);
    border-left-width: pxToRem(8);
    margin-right: pxToRem(5);
    margin-bottom: pxToRem(7);
}

.sub-category__link {
    background-size: pxToRem(30);
    padding-right: pxToRem(80);
    background-position: center right 30px;
}

.category__icon {
    font-size: pxToRem(28);
    margin-right: pxToRem(20);
    &.fa-home {
        font-size: pxToRem(37)
    }
    &.fa-car {
        font-size: pxToRem(30)
    }
    &.fa-pie-chart {
        font-size: pxToRem(29)
    }
    &.fa-pencil,
    &.fa-refresh {
        font-size: pxToRem(32)
    }
}

.sub-category__list {
    display: -ms-flexbox;
    display: flex;
    opacity: 0;
    visibility: hidden;
    @include sq-transition(.15s opacity ease-in-out, .15s visibility ease-in-out);
    .active>& {
        position: absolute;
        visibility: visible;
        opacity: 1;
        left: 0;
        right: 0;
        @include sq-transition(.3s opacity ease-in-out, .3s visibility ease-in-out);
    }
}