/* Module: S */
.contact__columns {
    // display: -ms-flexbox;
    // display: flex;
    .validate__form {
        max-width: 700px;
    }
    form,
    .contact__col {
        -ms-flex-positive: 1;
        flex-grow: 1;
    }
}

.contact__box {
    // padding-top: 0;
    flex-shrink: 0;
    // max-width: pxToRem(250);
    // margin-left: 1.5rem;
}

.contact__content {
    &:first-of-type {
        // padding-top: 0;
        margin-top: 0;
        // border-top: 0;
    }
    div {
        display: inline-block;
        vertical-align: top;
        width: 300px;
    }
}