// Base font
$base-font-scale: 1;  // The font scale multiplier (e.g. 2 = 32px/2em/2rem)
$base-font-pixel: 16; // Used for font size calculations & conversions
$base-font-family: 'Montserrat', Helvetica, Arial, Sans-serif;
$base-font-colour: #363636;

// Selection highlighting
$content-selection-highlight: #b3d4fc;

// colors
$blue-light1: #00aeef;
$global-bg: #fff;
$inner-bg: #f1f1f1;

$freen-dark1: #1f576c;
$grey-dark1: #b2b2b2;
$grey-dark2: #979797;
$grey_dark3: #bfbfbf;
$grey_dark4: #343434;
$grey_dark5: #777;
$grey-light1: #e1e1e1;
$grey-light2: #d8d8d8;
$grey-light3: #f1f1f1;
$purple-dark1: #3c2b76;
$red: #d0021b;

$color-primary: #00aeef;
$color-secondary: #343434;

// Transition
$default-transition:    .4s cubic-bezier(.77, 0, .175, 1);
$slow-transition:       1s cubic-bezier(.77, 0, .175, 1);
$fast-transition:       .25s cubic-bezier(.77, 0, .175, 1);
