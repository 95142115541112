/* Module: S */
main {
    font-size: pxToRem(18);
}

.page__wrapper {
    padding-left: pxToRem(34);
    padding-right: pxToRem(34);

    body.inner__page #main > & {
        padding-left: pxToRem(65);
        padding-right: pxToRem(65);
    }
}

.page__heading {
    font-size: pxToRem(38);
    text-align: left;
    margin-bottom: pxToRem(45);

    &:after {
        height: 2px;
        width: pxToRem(80);
        margin-top: pxToRem(15);
        margin-left: 0;
    }
}

h2 { font-size: pxToRem(34) }
h3 { font-size: pxToRem(30) }
h4 { font-size: pxToRem(24) }

h5 {
    font-size: pxToRem(18);
    margin-bottom: pxToRem(8);
}

h6 {
    font-size: pxToRem(18);
    margin-bottom: pxToRem(8);
}

.form__disclaimer {
    padding-right: 0;
}

.standard-btn {
  padding: 15px 75px 15px 25px;
  font-size: 16px;

    &:after {
        position:absolute;
        right: 20px;
        top:0;
        bottom:0;
        margin: auto 0;
        width:30px;
        height:30px;
        content:" ";
        background: url('{{file_dest}}/next-black.svg') center / cover no-repeat;
        @include ease-it(0.3);
    }

    &--active {
        &:after {
            @include sq-rotate(90);
        }
    }

    &:hover,
    &:active,
    &:focus {
        &:after {
            background: url('{{file_dest}}/next-white.svg') center / cover no-repeat;
        }
    }
}
