#main {
    blockquote {
        font-size: pxToRem(18);
        line-height: 1.65;
        padding-left: 2rem;
        margin-left: 3em;
        border-left-width: 3px;
    }
    ul {
        a {
            &[href$=".doc"],
            &[href$=".docx"],
            &[href$=".pdf"],
            &[href$=".exe"] {
                //font-size: pxToRem(16);
                &:before {
                    height: pxToRem(15);
                    width: pxToRem(15);
                    //margin-right: pxToRem(4);
                    margin-left: 4px; 
                }
                &:after {
                    margin-left: 3px;
                }
            }
        }
    }
}

img,
figure {
    &.pull-left {
        float: left;
        margin-bottom: .5rem;
        margin-right: 1rem;
        max-width: pxToRem(250);
    }
    &.pull-right {
        float: right;
        margin-bottom: .5rem;
        margin-left: 1rem;
        max-width: pxToRem(250);
    }
}


/*
--------------------
Modules
--------------------
*/