/* Module: S */
//
// Mixins
// -----------------------------------------

@mixin svg-arrow ($color, $position: up) {

    $degrees: 0;
    $height:  6px;
    $width:   18px;

    @if ($position == down) {
        $degrees: 180 18 6;
    } @else if ($position == left) {
        $degrees: -90 18 18;
        $width: 6px;
        $height: 18px;
    } @else if ($position == right) {
        $degrees: 90 6 6;
        $width: 6px;
        $height: 18px;
    }

    background: no-repeat url('data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20width%3D%22#{$width * 2}%22%20height%3D%22#{$height * 2}%22%3E%3Cpath%20fill%3D%22#{$color}%22%20transform%3D%22rotate(#{$degrees})%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E');
    background-size: 100% auto;
    width: $width;
    height: $height;
}

@mixin base-effects () {
    opacity: 0;
    pointer-events: none;
    @include sq-transition(all .18s ease-out .1s);
}

//
// Styles
// -----------------------------------------

// IE 11 button bugfix
button[data-balloon] {
    overflow: visible;
}

[data-balloon] {
    position: relative; // alt. absolute or fixed
    border-bottom: 1px dotted currentColor;

    &:after {
        @include base-effects();

        font-family: Helvetica, Arial, sans-serif;
        background: $balloon-bg;
        border-radius: 4px;
        color: #fff;
        content: attr(data-balloon);
        font-size: $balloon-base-size + 3;
        line-height: 1.45;
        padding: .5em .75em;
        position: absolute;
        width: 9rem;
        text-align: center;
        z-index: 10;
    }

    &:before {
        @include svg-arrow($balloon-bg);
        @include base-effects();

        content: '';
        position: absolute;
        z-index: 10;
    }

    &:hover, &[data-balloon-visible] {
        &:before,
        &:after {
            opacity: 1;
            pointer-events: auto;
        }
    }

    &[data-balloon-pos="up"],
    &[data-balloon-pos="top"] {
        &:after {
            bottom: 100%;
            left: 50%;
            margin-bottom: 5px + $balloon-arrow-height;
            @include sq-transform(translate(-50%, 10px));
            @include sq-transform-origin(top);
        }
        &:before {
            bottom: 100%;
            left: 50%;
            margin-bottom: 5px;
            @include sq-transform(translate(-50%, 10px));
            @include sq-transform-origin(top);
        }

        &:hover, &[data-balloon-visible] {
            &:after { @include sq-transform(translate(-50%, 0)) }
            &:before { @include sq-transform(translate(-50%, 0)) }
        }

    }

    &[data-balloon-pos='down'],
    &[data-balloon-pos="bottom"] {
        &:after {
            left: 50%;
            margin-top: 5px + $balloon-arrow-height;
            top: 100%;
            @include sq-transform(translate(-50%, -10px));
        }

        &:before {
            @include svg-arrow($balloon-bg, 'down');

            left: 50%;
            margin-top: 5px;
            top: 100%;
            @include sq-transform(translate(-50%, -10px));
        }

        &:hover, &[data-balloon-visible] {
            &:after { @include sq-transform(translate(-50%, 0)) }
            &:before { @include sq-transform(translate(-50%, 0)) }
        }
    }

    &[data-balloon-pos='left'] {
        &:after {
            margin-right: 5px + $balloon-arrow-height;
            right: 100%;
            top: 50%;
            @include sq-transform(translate(10px, -50%));
        }

        &:before {
            @include svg-arrow($balloon-bg, 'left');

            margin-right: 5px;
            right: 100%;
            top: 50%;
            @include sq-transform(translate(10px, -50%));
        }

        &:hover, &[data-balloon-visible] {
            &:after { @include sq-transform(translate(0, -50%)) }
            &:before { @include sq-transform(translate(0, -50%)) }
        }

    }

    &[data-balloon-pos='right'] {
        &:after {
            left: 100%;
            margin-left: 5px + $balloon-arrow-height;
            top: 50%;
            @include sq-transform(translate(-10px, -50%));
        }

        &:before {
            @include svg-arrow($balloon-bg, 'right');

            left: 100%;
            margin-left: 5px;
            top: 50%;
            @include sq-transform(translate(-10px, -50%));
        }

        &:hover, &[data-balloon-visible] {
            &:after { @include sq-transform(translate(0, -50%)) }
            &:before { @include sq-transform(translate(0, -50%)) }
        }
    }
}
