/* Module: S */
.article-navigation__link {
    font-size: pxToRem(20);
    border-bottom-width: 2px;
}

.article-pagination__title {
    font-size: pxToRem(18);

    .article-pagination__previous & {
        padding-left: pxToRem(80);
    }

    .article-pagination__next & {
        padding-right: pxToRem(80);
    }

    &:before {
        font-size: pxToRem(23);
        margin-bottom: pxToRem(8);
    }
}
