/* Tables */
table {
    width: 100%;
    border-collapse: collapse;
    margin: .5em 0;
}

caption {
    @include sq-font-size(.8);
    font-style: italic;
}

p {
    margin-top: 0;
}

/* Lists */
ul:not(.pagination, .article-pagination__list, .questionnaires__list, .tax-model__list, .search-result__list) {
    list-style: none;
    padding: 0;
    margin: pxToRem(20) 0;

    ul {
        margin-top: pxToRem(16);
        margin-bottom: pxToRem(16);
        margin-left: pxToRem(25);
    }

    li {
        position: relative;
        padding-left: pxToRem(22);
        margin-bottom: pxToRem(12);
        &:last-of-type { margin-bottom: 0 }
        &:before {
            content: '';
            position: absolute;
            top: pxToRem(10);
            left: 0;
            display: inline-block;
            background-color: #008bbf;
            border-radius: 50%;
            height: pxToRem(5);
            width: pxToRem(5);
        }

        li {
            &:before {
                border: 1px solid #008bbf;
                background-color: transparent;
            }
        }
    }
}

.fancy-radio ul li:before {
    display: none !important;
}

.fancy-radio ul li {
    padding-left:0px !important;
}

ol:not(.breadcrumbs__list, .article-navigation__list) {
    margin: pxToRem(15) 0;
    padding-left: pxToRem(16);

    li {
        padding-left: pxToRem(6);
        margin-bottom: pxToRem(12);
        &:last-of-type { margin-bottom: 0 }
    }
}

.pull-quote {
    border-top: 1px solid $base-font-colour;
    padding: 1em .5em 0 .5em;
    margin: 1em;
}

/* Highlight */
.highlight {
    background: #cccccc;
    @extend %inline-block;
    padding: .1em .5em;
}


/* Buttons */
.button {
    color: $base-font-colour;
    background-color: transparent;
    font-size: pxToRem(18);
    font-family: "Montserrat", Helvetica, Arial, Sans-serif;
    border: 1px solid transparent;
    display: inline-block;
    line-height: 1.45;
    padding: .95em 1.5em;
    @include sq-transition(background-color .15s linear, background-image .15s linear, color .15s linear);

    _::-webkit-full-page-media, _:future, :root & {
        @include sq-transition(background-color .15s linear, color .15s linear);
    }

    &:hover,
    &:focus {
        background-color: rgba(0, 0, 0, .05);
    }
}

.button--primary {
    background-color: #00aeef;
    background-image: url('data:image/svg+xml;charset=utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDMyLjYzNSAzMi42MzUiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDMyLjYzNSAzMi42MzU7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iMjRweCIgaGVpZ2h0PSIyNHB4Ij4KPGc+Cgk8cGF0aCBkPSJNMzIuMTM1LDE2LjgxN0gwLjVjLTAuMjc2LDAtMC41LTAuMjI0LTAuNS0wLjVzMC4yMjQtMC41LDAuNS0wLjVoMzEuNjM1YzAuMjc2LDAsMC41LDAuMjI0LDAuNSwwLjUgICBTMzIuNDExLDE2LjgxNywzMi4xMzUsMTYuODE3eiIgZmlsbD0iIzAwMDAwMCIvPgoJPHBhdGggZD0iTTE5LjU5OCwyOS4zNTNjLTAuMTI4LDAtMC4yNTYtMC4wNDktMC4zNTQtMC4xNDZjLTAuMTk1LTAuMTk1LTAuMTk1LTAuNTEyLDAtMC43MDdsMTIuMTg0LTEyLjE4NEwxOS4yNDQsNC4xMzYgICBjLTAuMTk1LTAuMTk1LTAuMTk1LTAuNTEyLDAtMC43MDdzMC41MTItMC4xOTUsMC43MDcsMGwxMi41MzcsMTIuNTMzYzAuMDk0LDAuMDk0LDAuMTQ2LDAuMjIxLDAuMTQ2LDAuMzU0ICAgcy0wLjA1MywwLjI2LTAuMTQ2LDAuMzU0TDE5Ljk1MSwyOS4yMDZDMTkuODU0LDI5LjMwNCwxOS43MjYsMjkuMzUzLDE5LjU5OCwyOS4zNTN6IiBmaWxsPSIjMDAwMDAwIi8+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==');
    background-repeat: no-repeat;
    background-position: center right 1.5em;
    padding-right: 3.75em;

    &:hover,
    &:focus,
    &.hover {
        color: #fff;
        background-color: #1f576c;
        background-image: url('data:image/svg+xml;charset=utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDMyLjYzNSAzMi42MzUiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDMyLjYzNSAzMi42MzU7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iMjRweCIgaGVpZ2h0PSIyNHB4Ij4KPGc+Cgk8cGF0aCBkPSJNMzIuMTM1LDE2LjgxN0gwLjVjLTAuMjc2LDAtMC41LTAuMjI0LTAuNS0wLjVzMC4yMjQtMC41LDAuNS0wLjVoMzEuNjM1YzAuMjc2LDAsMC41LDAuMjI0LDAuNSwwLjUgICBTMzIuNDExLDE2LjgxNywzMi4xMzUsMTYuODE3eiIgZmlsbD0iI0ZGRkZGRiIvPgoJPHBhdGggZD0iTTE5LjU5OCwyOS4zNTNjLTAuMTI4LDAtMC4yNTYtMC4wNDktMC4zNTQtMC4xNDZjLTAuMTk1LTAuMTk1LTAuMTk1LTAuNTEyLDAtMC43MDdsMTIuMTg0LTEyLjE4NEwxOS4yNDQsNC4xMzYgICBjLTAuMTk1LTAuMTk1LTAuMTk1LTAuNTEyLDAtMC43MDdzMC41MTItMC4xOTUsMC43MDcsMGwxMi41MzcsMTIuNTMzYzAuMDk0LDAuMDk0LDAuMTQ2LDAuMjIxLDAuMTQ2LDAuMzU0ICAgcy0wLjA1MywwLjI2LTAuMTQ2LDAuMzU0TDE5Ljk1MSwyOS4yMDZDMTkuODU0LDI5LjMwNCwxOS43MjYsMjkuMzUzLDE5LjU5OCwyOS4zNTN6IiBmaWxsPSIjRkZGRkZGIi8+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==');
    }

    &[disabled] {
        color: #777;
        background-color: darken(#fff, 20%);
        background-image: url('data:image/svg+xml;charset=utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDMyLjYzNSAzMi42MzUiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDMyLjYzNSAzMi42MzU7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iMjRweCIgaGVpZ2h0PSIyNHB4Ij4KPGc+Cgk8cGF0aCBkPSJNMzIuMTM1LDE2LjgxN0gwLjVjLTAuMjc2LDAtMC41LTAuMjI0LTAuNS0wLjVzMC4yMjQtMC41LDAuNS0wLjVoMzEuNjM1YzAuMjc2LDAsMC41LDAuMjI0LDAuNSwwLjUgICBTMzIuNDExLDE2LjgxNywzMi4xMzUsMTYuODE3eiIgZmlsbD0iIzc3Nzc3NyIvPgoJPHBhdGggZD0iTTE5LjU5OCwyOS4zNTNjLTAuMTI4LDAtMC4yNTYtMC4wNDktMC4zNTQtMC4xNDZjLTAuMTk1LTAuMTk1LTAuMTk1LTAuNTEyLDAtMC43MDdsMTIuMTg0LTEyLjE4NEwxOS4yNDQsNC4xMzYgICBjLTAuMTk1LTAuMTk1LTAuMTk1LTAuNTEyLDAtMC43MDdzMC41MTItMC4xOTUsMC43MDcsMGwxMi41MzcsMTIuNTMzYzAuMDk0LDAuMDk0LDAuMTQ2LDAuMjIxLDAuMTQ2LDAuMzU0ICAgcy0wLjA1MywwLjI2LTAuMTQ2LDAuMzU0TDE5Ljk1MSwyOS4yMDZDMTkuODU0LDI5LjMwNCwxOS43MjYsMjkuMzUzLDE5LjU5OCwyOS4zNTN6IiBmaWxsPSIjNzc3Nzc3Ii8+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==');
        cursor: not-allowed;
    }
}

.button--secondary {
    border-color: #00aeef;

    &:hover,
    &:focus,
    &.hover {
        background-color: #00aeef;
    }

    &[disabled] {
        color: #777;
        border-color: darken(#fff, 20%);
        cursor: not-allowed;
        &:hover { background-color: transparent }
    }
}

.button--large {
    @include sq-font-size(1.35);
    background-size: 34px;

}

.button--small {
    @include sq-font-size(.8);
    background-size: 16px;
}

.button--search {
    background-image: url('data:image/svg+xml;charset=utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDU2Ljk2NiA1Ni45NjYiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDU2Ljk2NiA1Ni45NjY7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iMjRweCIgaGVpZ2h0PSIyNHB4Ij4KPHBhdGggZD0iTTU1LjE0Niw1MS44ODdMNDEuNTg4LDM3Ljc4NmMzLjQ4Ni00LjE0NCw1LjM5Ni05LjM1OCw1LjM5Ni0xNC43ODZjMC0xMi42ODItMTAuMzE4LTIzLTIzLTIzcy0yMywxMC4zMTgtMjMsMjMgIHMxMC4zMTgsMjMsMjMsMjNjNC43NjEsMCw5LjI5OC0xLjQzNiwxMy4xNzctNC4xNjJsMTMuNjYxLDE0LjIwOGMwLjU3MSwwLjU5MywxLjMzOSwwLjkyLDIuMTYyLDAuOTIgIGMwLjc3OSwwLDEuNTE4LTAuMjk3LDIuMDc5LTAuODM3QzU2LjI1NSw1NC45ODIsNTYuMjkzLDUzLjA4LDU1LjE0Niw1MS44ODd6IE0yMy45ODQsNmM5LjM3NCwwLDE3LDcuNjI2LDE3LDE3cy03LjYyNiwxNy0xNywxNyAgcy0xNy03LjYyNi0xNy0xN1MxNC42MSw2LDIzLjk4NCw2eiIgZmlsbD0iIzM2MzYzNiIvPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K');
    background-position: center center;
    height: pxToRem(55);
    width: pxToRem(55);
    padding: 0;
    color: transparent;

    &:hover,
    &:focus {
        color: transparent;
        background-image: url('data:image/svg+xml;charset=utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDU2Ljk2NiA1Ni45NjYiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDU2Ljk2NiA1Ni45NjY7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iMjRweCIgaGVpZ2h0PSIyNHB4Ij4KPHBhdGggZD0iTTU1LjE0Niw1MS44ODdMNDEuNTg4LDM3Ljc4NmMzLjQ4Ni00LjE0NCw1LjM5Ni05LjM1OCw1LjM5Ni0xNC43ODZjMC0xMi42ODItMTAuMzE4LTIzLTIzLTIzcy0yMywxMC4zMTgtMjMsMjMgIHMxMC4zMTgsMjMsMjMsMjNjNC43NjEsMCw5LjI5OC0xLjQzNiwxMy4xNzctNC4xNjJsMTMuNjYxLDE0LjIwOGMwLjU3MSwwLjU5MywxLjMzOSwwLjkyLDIuMTYyLDAuOTIgIGMwLjc3OSwwLDEuNTE4LTAuMjk3LDIuMDc5LTAuODM3QzU2LjI1NSw1NC45ODIsNTYuMjkzLDUzLjA4LDU1LjE0Niw1MS44ODd6IE0yMy45ODQsNmM5LjM3NCwwLDE3LDcuNjI2LDE3LDE3cy03LjYyNiwxNy0xNywxNyAgcy0xNy03LjYyNi0xNy0xN1MxNC42MSw2LDIzLjk4NCw2eiIgZmlsbD0iI0ZGRkZGRiIvPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K');
    }
}
