/* Module: S */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    src: url('{{file_dest}}/montserrat-light.eot');
    src: local('Montserrat Light'),
    local('Montserrat-Light'),
    url('{{file_dest}}/montserrat-light.eot?#iefix') format('embedded-opentype'),
    url('{{file_dest}}/montserrat-light.woff2') format('woff2'),
    url('{{file_dest}}/montserrat-light.woff') format('woff'),
    url('{{file_dest}}/montserrat-light.ttf') format('truetype'),
    url('{{file_dest}}/montserrat-light.svg#Montserrat') format('svg');
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: url('{{file_dest}}/montserrat-regular.eot');
    src: local('Montserrat Regular'),
    local('Montserrat-Regular'),
    url('{{file_dest}}/montserrat-regular.eot?#iefix') format('embedded-opentype'),
    url('{{file_dest}}/montserrat-regular.woff2') format('woff2'),
    url('{{file_dest}}/montserrat-regular.woff') format('woff'),
    url('{{file_dest}}/montserrat-regular.ttf') format('truetype'),
    url('{{file_dest}}/montserrat-regular.svg#Montserrat') format('svg');
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    src: url('{{file_dest}}/montserrat-semibold.eot');
    src: local('Montserrat SemiBold'),
    local('Montserrat-SemiBold'),
    url('{{file_dest}}/montserrat-semibold.eot?#iefix') format('embedded-opentype'),
    url('{{file_dest}}/montserrat-semibold.woff2') format('woff2'),
    url('{{file_dest}}/montserrat-semibold.woff') format('woff'),
    url('{{file_dest}}/montserrat-semibold.ttf') format('truetype'),
    url('{{file_dest}}/montserrat-semibold.svg#Montserrat') format('svg');
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    src: url('{{file_dest}}/source-sans-pro-regular.eot');
    src: local('Source Sans Pro'),
    local('SourceSansPro-Regular'),
    url('{{file_dest}}/source-sans-pro-regular.eot?#iefix') format('embedded-opentype'),
    url('{{file_dest}}/source-sans-pro-regular.woff2') format('woff2'),
    url('{{file_dest}}/source-sans-pro-regular.woff') format('woff'),
    url('{{file_dest}}/source-sans-pro-regular.ttf') format('truetype'),
    url('{{file_dest}}/source-sans-pro-regular.svg#SourceSansPro') format('svg');
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    src: url('{{file_dest}}/source-sans-pro-bold.eot');
    src: local('Source Sans Pro Semibold'),
    local('SourceSansPro-Semibold'),
    url('{{file_dest}}/source-sans-pro-bold.eot?#iefix') format('embedded-opentype'),
    url('{{file_dest}}/source-sans-pro-bold.woff2') format('woff2'),
    url('{{file_dest}}/source-sans-pro-bold.woff') format('woff'),
    url('{{file_dest}}/source-sans-pro-bold.ttf') format('truetype'),
    url('{{file_dest}}/source-sans-pro-bold.svg#SourceSansPro') format('svg');
}

@font-face {
    font-family: 'Lato-Regular';
    src: url('{{file_dest}}/Lato-Regular.ttf');
}

main {
    font-family: 'Source Sans Pro', Helvetica, Arial, Sans-serif;
    font-size: pxToRem(15);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 2.5rem;
    font-family: "Montserrat", Helvetica, Arial, Sans-serif;
    line-height: 1.3;
}

h2 {
    font-size: pxToRem(27);
    font-weight: 400;
    margin-bottom: pxToRem(15);
}

h3 {
    font-size: pxToRem(22);
    font-weight: 600;
    margin-bottom: pxToRem(12);
}

h4 {
    font-size: pxToRem(18);
    font-weight: 600;
    margin-bottom: pxToRem(12);
}

h5 {
    font-size: pxToRem(16);
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: pxToRem(12);
}

h6 {
    font-size: pxToRem(16);
    font-weight: 600;
    margin-bottom: pxToRem(12);
}

.page__wrapper {
    max-width: pxToRem(1280);
    padding-left: pxToRem(15);
    padding-right: pxToRem(15);
    margin-left: auto;
    margin-right: auto;
    body.inner__page #main & {
        max-width: pxToRem(1080);
    }
    #main & {
        >h1:first-child,
        >h2:first-child,
        >h3:first-child,
        >h4:first-child,
        >h5:first-child,
        >h6:first-child {
            margin-top: 0;
        }
    }
}

.page__heading {
    font-size: pxToRem(30);
    font-weight: 600;
    text-align: center;
    margin-bottom: pxToRem(35);
    &:after {
        content: '';
        display: block;
        height: 1px;
        width: pxToRem(50);
        background-color: #00aeef;
        margin-top: pxToRem(25);
        margin-left: auto;
        margin-right: auto;
    }
}

.main {
    max-width: 1440px;
    margin: 0 auto;
    text-align: left;
    overflow: hidden;
    background: $grey-light3;
}

.standard-btn {
    margin: 0 auto;
    padding: 15px 25px;
    background: $blue-light1;
    border: 0;
    outline: 0;
    position: relative;
    color: $grey_dark4;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    &:hover,
    &:active,
    &:focus {
        color: white;
        background: $freen-dark1;
    }
}

.ease-it-150 {
    @include ease-it(.15)
}

.ease-it-300 {
    @include ease-it(.3)
}

.ease-it-500 {
    @include ease-it(.5)
}

.ease-it-650 {
    @include ease-it(.65)
}

.ease-it-800 {
    @include ease-it(.8)
}

.ease-it-1000 {
    @include ease-it(1.5)
}

.ease-it-1200 {
    @include ease-it(1.5)
}

.ease-it-1500 {
    @include ease-it(1.5)
}

.ease-it-0 {
    @include ease-it(0)
}

.rotate-it {
    @include sq-rotate(180)
}

.ease-it-none {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
}

.form__group {
    margin-bottom: pxToRem(35);
    &:last-of-type {
        margin-bottom: 0
    }
    >label:not(.error),
    >legend {
        display: block;
        font-size: pxToRem(18);
        font-family: 'Source Sans Pro', Helvetica, Arial, Sans-serif;
        font-weight: 600;
        margin-bottom: pxToRem(8);
    }
    label.error {
        color: #b63128;
        display: block;
        margin-top: pxToRem(8);
    }
}

.form__buttons {
    // text-align: center;
    margin-top: pxToRem(35);
    input+input,
    button+button {
        margin-left: pxToRem(10);
    }
}

.form__disclaimer {
    color: #000;
    font-size: pxToRem(13);
    margin-top: pxToRem(48);
    padding-right: 35%;
    @media screen and (min-width: 22.5rem) {
        padding-right: 44%;
    }
}

.form__control,
.custom-select__opener {
    width: 100%;
    display: block;
    border: 1px solid #bbbbbb;
    height: pxToRem(55);
    font-family: 'Source Sans Pro', Helvetica, Arial, Sans-serif;
    line-height: pxToRem(55);
    padding-left: pxToRem(20);
    padding-right: pxToRem(20);
    @include sq-transition(.15s border-color ease-in-out,
    .15s background-color ease-in-out);
    &:focus {
        outline: 0;
        border-color: darken(#bbbbbb, 20%);
    }
    &.valid {
        box-shadow: inset 5px 0 0 #00aeef
    }
    &.error {
        box-shadow: inset 5px 0 0 #b63128
    }
}

select.form__control,
.custom-select__opener {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #d8d8d8;
    background-image: url('data:image/svg+xml;charset=utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDQwNC4zMDggNDA0LjMwOSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDA0LjMwOCA0MDQuMzA5OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPHBhdGggZD0iTTAsMTAxLjA4aDQwNC4zMDhMMjAyLjE1MSwzMDMuMjI5TDAsMTAxLjA4eiIgZmlsbD0iIzM2MzYzNiIvPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=');
    background-repeat: no-repeat;
    background-size: 12px;
    background-position: center right 20px;
    border-radius: 0;
    padding-right: pxToRem(45);
}

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
    select.form__control {
        background-image: none;
        padding-right: 0;
    }
}

.custom-select__opener {
    .is-open & {
        background-image: url('data:image/svg+xml;charset=utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDQwNC4zMDggNDA0LjMwOSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDA0LjMwOCA0MDQuMzA5OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPHBhdGggZD0iTTQwNC4zMDgsMzAzLjIyOUgwTDIwMi4xNTcsMTAxLjA4TDQwNC4zMDgsMzAzLjIyOXoiIGZpbGw9IiMzNjM2MzYiLz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K');
    }
}

input.form__control {
    background-color: transparent;
    &:focus {
        background-color: #fff
    }
}

textarea.form__control {
    height: auto;
    padding: pxToRem(15);
    line-height: 1.45;
    resize: vertical;
}

.form__options--inline {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    +.form__options--inline,
    +*+.form__options--inline {
        margin-top: pxToRem(14)
    }
    .input__option {
        margin-right: pxToRem(14);
        margin-bottom: 0;
        &:last-of-type {
            margin-bottom: 0
        }
    }
}

.input__option {
    margin-bottom: pxToRem(14);
    &:last-of-type {
        margin-bottom: 0
    }
    >input[type="radio"],
    >input[type="checkbox"] {
        @extend .visuallyhidden;
        +label {
            position: relative;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-align: center;
            align-items: center;
            line-height: pxToRem(20);
            &:before {
                content: '';
                display: inline-block;
                background-color: #d8d8d8;
                border: 1px solid #979797;
                height: pxToRem(20);
                width: pxToRem(20);
                border-radius: 50%;
                margin-right: pxToRem(10);
            }
            &:after {
                content: '';
                position: absolute;
                top: 50%;
                left: pxToRem(5);
                display: inline-block;
                height: pxToRem(10);
                width: pxToRem(10);
                margin-top: pxToRem(-5);
                border-radius: 50%;
                @include sq-transition(.15s background-color linear,
                .15s border linear);
            }
            &:hover,
            &:focus {
                &:after {
                    background-color: darken(#d8d8d8, 15%)
                }
            }
        }
        &:focus {
            +label {
                &:before {
                    border-width: 2px
                }
            }
        }
        &:checked {
            +label {
                &:after {
                    background-color: #343434
                }
            }
        }
        &.error {
            +label {
                &:before {
                    border-color: #b63128
                }
            }
        }
        &[disabled] {
            +label {
                opacity: 0.5;
                pointer-events: none
            }
        }
    }
    >input[type="checkbox"] {
        +label {
            &:before {
                border-radius: 0;
                @include sq-transition(.15s box-shadow linear);
            }
            &:after {
                background-color: transparent;
                height: pxToRem(18);
                width: pxToRem(10);
                top: pxToRem(1);
                left: pxToRem(8);
                border-radius: 0;
                border-right: 4px solid transparent;
                border-bottom: 4px solid transparent;
                @include sq-transform(rotate(40deg));
            }
            &:hover,
            &:focus {
                &:before {
                    box-shadow: inset 0 0 0 3px darken(#d8d8d8, 10%)
                }
                &:after {
                    background-color: transparent
                }
            }
        }
        &:checked {
            +label {
                &:before {
                    box-shadow: none
                }
                &:after {
                    background-color: transparent;
                    border-color: #343434;
                }
            }
        }
    }
}

.custom-select__container {
    position: relative;
    &.is-disabled {
        opacity: .333
    }
    &.is-open {
        .custom-select__panel {
            max-height: pxToRem(200);
            overflow-y: auto;
            border: 1px solid darken(#d9d9d9, 5%);
            border-top: 0;
        }
    }
    select {
        visibility: hidden;
        position: absolute;
        top: 0;
        left: 0;
    }
}

.custom-select__opener {
    cursor: pointer;
    &:focus {
        outline: 0
    }
}

.custom-select__panel {
    background-color: #fff;
    max-height: 0;
    overflow: hidden;
    position: absolute;
    top: 100%;
    z-index: 1;
    width: 100%;
    @include sq-transition(max-height .25s ease-out,
    overflow-y 0.1s 0.5s);
}

.custom-select__option {
    padding: pxToRem(10) pxToRem(20);
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-bottom: 1px solid darken(#d9d9d9, 5%);
    @include sq-transition(.15s background-color ease-in-out);
    &.has-focus {
        background-color: #00aeef
    }
    &.is-selected {
        cursor: default;
        background-color: darken(#fff, 8%);
        &:after {
            content: ' (selected)';
            color: darken(#fff, 30%);
            font-style: italic;
            font-size: 80%;
            margin-left: pxToRem(4);
        }
    }
    &:last-of-type {
        border-bottom: 0
    }
}

.custom-select__optgroup {
    >.custom-select__option {
        padding-left: 2em;
    }
    &:before {
        content: attr(data-label);
        display: block;
        padding: 0.5em;
        color: #888;
    }
}