/* Module: S */
.footer {
    body.inner__page & {
        margin-top: 6.5rem;
    }
}

.footer__section {
    padding-top: pxToRem(25);
    padding-bottom: pxToRem(25);
}

.footer-top {
    padding-top: pxToRem(34);
    padding-bottom: pxToRem(34);
}

.footer-bottom {
    .footer__link {
        font-size: pxToRem(15);
        color: $desktop_footer-link--color;
    }
}

.page__update {
    color: $desktop_footer-link--color;
    font-size: pxToRem(11);
    margin-top: 0;
}

.footer__side-logos {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
}
