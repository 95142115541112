/* Module: S */
.suggestions__container {
    background-color: $section--bg;
    padding-top: pxToRem(38);
    padding-bottom: pxToRem(25);
}

.suggestion__tile {
    position: relative;
    overflow: hidden;
    margin-bottom: pxToRem(15);

    &:last-of-type { margin-bottom: 0 }

    .suggestion__link:hover,
    .suggestion__link:focus {
        .suggestion__image { @include sq-transform(scale(1.05)) }
    }

    &:nth-child(1), &:nth-child(5n) {
        .suggestion__heading { background-color: $heading-1--bg }

        .suggestion__link:hover,
        .suggestion__link:focus {
            .suggestion__heading { background-color: darken($heading-1--bg, 8%) }
        }
    }

    &:nth-child(2), &:nth-child(6n) {
        .suggestion__heading { background-color: $heading-2--bg }

        .suggestion__link:hover,
        .suggestion__link:focus {
            .suggestion__heading { background-color: darken($heading-2--bg, 8%) }
        }
    }

    &:nth-child(3), &:nth-child(7n) {
        .suggestion__heading {
            color: #333;
            background-color: $heading-3--bg
        }

        .suggestion__icon {
            fill: #333;
        }

        .suggestion__link:hover,
        .suggestion__link:focus {
            .suggestion__heading { background-color: darken($heading-3--bg, 8%) }
        }
    }

    &:nth-child(4), &:nth-child(8n) {
        .suggestion__heading { background-color: darken($heading-4--bg, 8%) }

        .suggestion__link:hover,
        .suggestion__link:focus {
            .suggestion__heading { background-color: darken($heading-4--bg, 8%) }
        }
    }
}

.suggestion__question {
    font-size: pxToRem(22);
    font-weight: 600;
    color: $question--color;
    margin-top: 0;
    margin-bottom: pxToRem(15);
}

.suggestion__link,
.suggestion__image {
    text-decoration: none;
    display: block;
}

.suggestion__image {
    width: 100%;
    object-fit: cover;
    min-height: pxToRem(165);
    @include sq-transition(.3s transform ease-in-out);
}

.suggestion__heading {
    position: absolute;
    bottom: pxToRem(10);
    left: 0;
    line-height: 1.55;
    color: $heading-text--color;
    font-size: pxToRem(14);
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 0;
    padding: pxToRem(10) pxToRem(15);
    @include sq-transition(.3s background-color ease-in-out);
}

.suggestion__icon {
    fill: $heading-text--color;
    display: block;
    float: right;
    height: pxToRem(24);
    width: pxToRem(24);
    margin-top: -2px;
    margin-left: pxToRem(45);
}
