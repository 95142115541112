/* Module: S */
.c-accordion__title {
    min-height: $accordion-item-height-desktop;
    line-height: $accordion-item-height-desktop;
    padding-top: pxToRem(8);
    padding-bottom: pxToRem(8);

    h3 {
        padding: 0 pxToRem(35);

        .c-accordion.js-is-tabs & {
            text-align: center;
            line-height: 1.4;
            padding: 0 1rem;
        }
    }
}

.c-accordion__panel__inner {
    padding: pxToRem(45) pxToRem(35);

    .c-accordion.js-is-tabs & {
        padding: pxToRem(20) 0;
    }
}
