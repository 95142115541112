/* Module: S */
.ques-css-js__content {
  padding-left:0;
  &:before{ //background on mobile
    width:0;
    height:0;
  }
}


.ques-css-js__content__progress-bar{
  width:100%;
  left: auto;
  @include sq-rotate(0);
  position: relative;
  margin-bottom:95px;

  &--floating{ // reset class floating for tablet and desktop
    top:0;
  }

}

.ques-css-js__content__progress-circle{
  @include sq-rotate(0);
  &:before{
    @include sq-rotate(0);
  }
}

.ques-css-js__next-back{
  bottom: -75px;
}
