/* Module: S */
// Styles

.c-accordion {
  @include sq-transition(height $accordion-open-transition);
  border-left: $accordion-border-x;
  border-right: $accordion-border-x;
  border-bottom: $accordion-border-y;
  border-top: $accordion-border-y;

  .no-js & {
    height: auto;
  }
}

.c-accordion__item {
  margin-bottom: pxToRem(3);
}

.c-accordion__title {
  @include sq-transition(background-color $accordion-ui-transition,
                         color $accordion-ui-transition,
                         opacity $accordion-ui-transition);

  background-color: $accordion-title-bg-color;
  color: $accordion-title-color;
  display: block;
  min-height: $accordion-item-height;
  line-height: $accordion-item-height;
  outline: none;
  padding: 0;
  text-align: left;
  text-decoration: none;
  width: 100%;
  border: 0;
  padding-top: pxToRem(15);
  padding-bottom: pxToRem(15);

  .c-accordion:not(.js-is-tabs) & {
    position: relative;

    &::after {
      background-image: url('data:image/svg+xml;charset=utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMS4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDQ5MS44NiA0OTEuODYiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQ5MS44NiA0OTEuODY7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iMjRweCIgaGVpZ2h0PSIyNHB4Ij4KPGc+Cgk8Zz4KCQk8cGF0aCBkPSJNNDY1LjE2NywyMTEuNjE0SDI4MC4yNDVWMjYuNjkxYzAtOC40MjQtMTEuNDM5LTI2LjY5LTM0LjMxNi0yNi42OXMtMzQuMzE2LDE4LjI2Ny0zNC4zMTYsMjYuNjl2MTg0LjkyNEgyNi42OSAgICBDMTguMjY3LDIxMS42MTQsMCwyMjMuMDUzLDAsMjQ1LjkyOXMxOC4yNjcsMzQuMzE2LDI2LjY5LDM0LjMxNmgxODQuOTI0djE4NC45MjRjMCw4LjQyMiwxMS40MzgsMjYuNjksMzQuMzE2LDI2LjY5ICAgIHMzNC4zMTYtMTguMjY4LDM0LjMxNi0yNi42OVYyODAuMjQ1SDQ2NS4xN2M4LjQyMiwwLDI2LjY5LTExLjQzOCwyNi42OS0zNC4zMTZTNDczLjU5LDIxMS42MTQsNDY1LjE2NywyMTEuNjE0eiIgZmlsbD0iIzAwYWVlZiIvPgoJPC9nPgoJPGc+Cgk8L2c+Cgk8Zz4KCTwvZz4KCTxnPgoJPC9nPgoJPGc+Cgk8L2c+Cgk8Zz4KCTwvZz4KCTxnPgoJPC9nPgoJPGc+Cgk8L2c+Cgk8Zz4KCTwvZz4KCTxnPgoJPC9nPgoJPGc+Cgk8L2c+Cgk8Zz4KCTwvZz4KCTxnPgoJPC9nPgoJPGc+Cgk8L2c+Cgk8Zz4KCTwvZz4KCTxnPgoJPC9nPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=');
      background-repeat: no-repeat;
      background-size: 14px;
      content: '';
      display: block;
      height: pxToRem(14);
      width: pxToRem(14);
      position: absolute;
      right: pxToRem(15);
      margin-top: pxToRem(-7);
      top: 50%;
    }
  }

  .c-accordion__item.is-active & {
    background-color: $accordion-active-title-bg-color;
    color: $accordion-active-title-color;

    h3 { font-weight: 600 }

    &::after {
        background-image: url('data:image/svg+xml;charset=utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMS4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDQ5MS44NTggNDkxLjg1OCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDkxLjg1OCA0OTEuODU4OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgd2lkdGg9IjI0cHgiIGhlaWdodD0iMjRweCI+CjxnPgoJPGc+CgkJPHBhdGggZD0iTTQ2NS4xNjcsMjExLjYxM0gyNDAuMjFIMjYuNjljLTguNDI0LDAtMjYuNjksMTEuNDM5LTI2LjY5LDM0LjMxNnMxOC4yNjcsMzQuMzE2LDI2LjY5LDM0LjMxNmgyMTMuNTJoMjI0Ljk1OSAgICBjOC40MjEsMCwyNi42ODktMTEuNDM5LDI2LjY4OS0zNC4zMTZTNDczLjU5LDIxMS42MTMsNDY1LjE2NywyMTEuNjEzeiIgZmlsbD0iIzAwYWVlZiIvPgoJPC9nPgoJPGc+Cgk8L2c+Cgk8Zz4KCTwvZz4KCTxnPgoJPC9nPgoJPGc+Cgk8L2c+Cgk8Zz4KCTwvZz4KCTxnPgoJPC9nPgoJPGc+Cgk8L2c+Cgk8Zz4KCTwvZz4KCTxnPgoJPC9nPgoJPGc+Cgk8L2c+Cgk8Zz4KCTwvZz4KCTxnPgoJPC9nPgoJPGc+Cgk8L2c+Cgk8Zz4KCTwvZz4KCTxnPgoJPC9nPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=');
    }
  }

  &:hover,
  &:focus {
    background-color: $accordion-title-hover-bg-color;
  }

  &:focus {
      outline: 1px dotted $accordion-active-title-color;
      outline-offset: 2px;
  }

  h3 {
    font-size: pxToRem(15);
    font-weight: 400;
    margin: 0;
    padding: 0 pxToRem(35) 0 $accordion-padding-default;
    line-height: 1.4;
  }
}

.c-accordion__panel {
  @include sq-font-size($accordion-font-size);
  @include sq-transition(max-height $accordion-open-transition);

  background-color: $accordion-panel-bg-color;
  overflow: hidden;

  .js-enabled & {
    max-height: 0;
  }

  .c-accordion__item:not(.is-active) & {
    // override max-height value set from JS
    max-height: 0 !important;
  }
}

.c-accordion__panel__inner {
  padding: $accordion-panel-padding;
}

// Tab styles
.c-accordion.js-is-tabs {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;

  .c-accordion__item {
    width: $accordion-tab-width;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-negative: 1;
    flex-shrink: 1;
    z-index: 0;

    &:not(:first-child) {
      border-left: $accordion-tab-margin solid transparent;
    }

    &.is-current {
      z-index: 2;

      .c-accordion__title {
        background-color: $accordion-active-tab-bg-color;
        border-bottom: none;
        color: $accordion-active-tab-color;
        opacity: 1;
      }

      .c-accordion__panel {
        opacity: 1;
      }
    }
  }

  .c-accordion__title {
    @include sq-transition(background-color $accordion-ui-transition);

    background-color: $accordion-tab-bg-color;
    color: $accordion-tab-color;
    opacity: $accordion-tab-opacity;

    &:hover {
      background-color: $accordion-tab-hover-bg-color;
    }
  }

  .c-accordion__panel {
    @include sq-transition(opacity $accordion-panel-transition);

    left: 0;
    border-top: pxToRem(1) solid #00aeef;
    max-height: initial;
    opacity: 0;
    position: absolute;
    width: 100%;
    z-index: -1;
  }
}
