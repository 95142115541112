/* Module: S */
.alert {
    position: relative;
    display: none;
    color: #343434;
    background-color: #00aeef;
    padding: pxToRem(14) 0;
}

.alert__content {
    position: relative;
    color: #363636;
    font-family: 'Source Sans Pro', Helvetica, Arial, Sans-serif;
    font-size: pxToRem(16);
    font-weight: 300;
    line-height: 1.35;
    border-top: 1px solid #363636;
    padding-top: 1rem;
    margin-top: 2.75rem;

    &:before {
        content: 'ALERT';
        font-size: pxToRem(22);
        font-weight: bold;
        position: absolute;
        left: pxToRem(35);
        top: pxToRem(-30);
        margin-top: pxToRem(-14);
    }

    &:after {
        content: '';
        position: absolute;
        left: 0;
        top: pxToRem(-30);
        margin-top: pxToRem(-12.5);
        background-image: url('{{file_dest}}/icon-alert.svg');
        background-repeat: no-repeat;
        background-size: 25px;
        position: absolute;
        height: pxToRem(25);
        width: pxToRem(25);
        display: block;
    }

    p {
        margin: 0;
    }
}

.alert__close {
    position: absolute;
    display: block;
    top: pxToRem(12);
    right: pxToRem(15);
    border: 0;
    padding: pxToRem(8) pxToRem(10);
    background-color: transparent;
    border-radius: 4px;
    @include sq-transition(.15s background-color linear);

    &:hover,
    &:focus {
        background-color: rgba(0, 0, 0, .15);
        outline: 0;
    }
}

.alert__icon {
    display: block;
    height: pxToRem(20);
    width: pxToRem(20);
}
