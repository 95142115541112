/* Module: S */
.article-navigation {
    overflow: hidden;
    max-height: pxToRem(51);
    margin-bottom: pxToRem(35);
    @include sq-transition(.35s max-height ease-in-out);
}

.article-navigation__toggler {
    position: relative;
    color: $base-font-colour;
    font-size: pxToRem(15);
    font-weight: 600;
    text-align: left;
    height: pxToRem(51);
    padding: pxToRem(15) pxToRem(35) pxToRem(15) pxToRem(15);
    background-color: #00aeef;
    display: block;
    width: 100%;
    border: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    @include sq-transition(.125s background-color linear);

    &:hover,
    &:focus {
        background-color: darken(#00aeef, 2.5%);
        outline: 0;
    }

    &:after {
        position: absolute;
        top: 50%;
        right: pxToRem(15);
        margin-top: pxToRem(-2.5);
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 4.5px 0 4.5px;
        border-color: #343434 transparent transparent transparent;
    }

    .active & {
        &:after {
            border-width: 0 4.5px 5px 4.5px;
            border-color: transparent transparent #343434 transparent;
        }
    }
}

.article-navigation__list {
    @extend %list-reset;
    background-color: #fff;
    padding: pxToRem(5) pxToRem(15);
}

.article-navigation__item {
    border-bottom: 1px solid darken(#fff, 8%);
    &:last-of-type { border-bottom: 0 }
}

.article-navigation__link {
    display: block;
    font-size: pxToRem(15);
    color: $base-font-colour;
    padding: pxToRem(8) 0;
    text-decoration: none;

    &.active {
        font-weight: 600;
        text-decoration: underline;
    }

    &:focus { outline: 0 }
}

.article-pagination {
    margin-top: 2rem;
}

.article-pagination__list {
    @extend %list-reset;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;

    &.hide-prev {
        -ms-flex-pack: end;
        justify-content: flex-end;
    }

    &.hide-next {
        -ms-flex-pack: start;
        justify-content: flex-start;
    }
}

.article-pagination__previous {
    .hide-prev > & { display: none }
}

.article-pagination__next {
    .hide-next > & { display: none }
}

.article-pagination__link {
    display: block;
    color: $base-font-colour;

    &:hover,
    &:focus {
        text-decoration: none;
    }
}

.article-pagination__indicator {
    font-size: pxToRem(15);
    font-family: "Montserrat", Helvetica, Arial, Sans-serif;
    font-weight: 600;
    line-height: 1.25;
    width: pxToRem(90);

    &:before {
        content: '';
        display: block;
        background-image: url('data:image/svg+xml;charset=utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDMyLjYzNSAzMi42MzUiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDMyLjYzNSAzMi42MzU7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iMjRweCIgaGVpZ2h0PSIyNHB4Ij4KPGc+Cgk8cGF0aCBkPSJNMzIuMTM1LDE2LjgxN0gwLjVjLTAuMjc2LDAtMC41LTAuMjI0LTAuNS0wLjVzMC4yMjQtMC41LDAuNS0wLjVoMzEuNjM1YzAuMjc2LDAsMC41LDAuMjI0LDAuNSwwLjUgICBTMzIuNDExLDE2LjgxNywzMi4xMzUsMTYuODE3eiIgZmlsbD0iIzAwYWVlZiIvPgoJPHBhdGggZD0iTTE5LjU5OCwyOS4zNTNjLTAuMTI4LDAtMC4yNTYtMC4wNDktMC4zNTQtMC4xNDZjLTAuMTk1LTAuMTk1LTAuMTk1LTAuNTEyLDAtMC43MDdsMTIuMTg0LTEyLjE4NEwxOS4yNDQsNC4xMzYgICBjLTAuMTk1LTAuMTk1LTAuMTk1LTAuNTEyLDAtMC43MDdzMC41MTItMC4xOTUsMC43MDcsMGwxMi41MzcsMTIuNTMzYzAuMDk0LDAuMDk0LDAuMTQ2LDAuMjIxLDAuMTQ2LDAuMzU0ICAgcy0wLjA1MywwLjI2LTAuMTQ2LDAuMzU0TDE5Ljk1MSwyOS4yMDZDMTkuODU0LDI5LjMwNCwxOS43MjYsMjkuMzUzLDE5LjU5OCwyOS4zNTN6IiBmaWxsPSIjMDBhZWVmIi8+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==');
        background-repeat: no-repeat;
        background-position: center right;
        background-size: 32px;
        height: pxToRem(32);
        width: pxToRem(90);
        margin-bottom: pxToRem(10);
    }

    .article-pagination__previous & {
        &:before {
            @include sq-transform(rotate(180deg));
        }
    }

    .article-pagination__next & {
        text-align: right;
    }
}

.article-pagination__title {
    display: none;
}

.spinner {
    position: relative;
    margin: pxToRem(70) auto 0;
    width: pxToRem(72);
    text-align: center;
    display: -ms-flexbox;
    display: flex;

    &:after {
        content: 'Loading';
        position: absolute;
        top: 100%;
        margin-top: pxToRem(4);
        font-weight: 600;
        font-family: "Montserrat", Helvetica, Arial, Sans-serif;
        text-transform: uppercase;
    }

    > div {
        width: pxToRem(20);
        height: pxToRem(20);
        background-color: #333;
        margin-right: pxToRem(6);
        border-radius: 100%;
        display: block;
        -webkit-animation: bounceDelay 1.25s infinite ease-in-out both;
        animation: bounceDelay 1.25s infinite ease-in-out both;

        &:last-of-type { margin-right: 0 }
    }

    .bounce1 {
        -webkit-animation-delay: -0.32s;
        animation-delay: -0.32s;
    }

    .bounce2 {
        -webkit-animation-delay: -0.16s;
        animation-delay: -0.16s;
    }
}

@-webkit-keyframes bounceDelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes bounceDelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    40% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
    }
}
