/* Module: S */
.link-listing {
    &__heading {
        min-height: 350px;
    }
}

.link-listing__banner {
    margin-top: 0px;
    .page__wrapper {
        padding-left: 4.0625rem;
    }
}