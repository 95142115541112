/* Module: S */
.search-query__container {
    display: flex;
    justify-content: space-between;
    input[type="text"] {
        width: calc(100% - 70px);
    }
}

.search-results-found {
    font-size: pxToRem(20);
    margin-top: pxToRem(25);
}

.search-result__list {
    @extend %list-reset;
    margin-top: pxToRem(45);
    .search-results-found+& {
        margin-top: pxToRem(25);
    }
}

.search-result__item {
    margin-bottom: pxToRem(15);
    &:last-of-type {
        margin-bottom: 0
    }
}

.search-result__link {
    position: relative;
    color: $base-font-colour;
    display: block;
    border: 1px solid #00aeef;
    padding: pxToRem(15);
    @include sq-transition(.125s all linear);
    &:hover,
    &:focus {
        color: #fff;
        text-decoration: none;
        background-color: #00aeef;
        .search-result__description {
            color: #fff
        }
    }
}

.search-result__description {
    color: lighten($base-font-colour, 12%);
    line-height: 1.4;
    @include sq-transition(.125s color linear);
}

.search-result__heading {
    font-size: pxToRem(22);
    font-weight: 600;
    margin-top: 0;
    margin-bottom: pxToRem(5);
}

.search-result__date {
    font-weight: 600;
    display: block;
    margin-bottom: pxToRem(3);
    &:before {
        font-weight: 400;
        content: 'Published: ';
    }
}

.news__list {
    .search-result__link:before {
        display: none !important;
    }
}