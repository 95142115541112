/* Module: S */
.proposal__container {
    padding-top: pxToRem(27);
    padding-bottom: pxToRem(35);

    body.inner__page & {
        padding-bottom: 0;

        > .page__wrapper {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

.proposal__tile {
    position: relative;
    margin-bottom: pxToRem(45);

    &:last-of-type { margin-bottom: 0 }

    &:nth-child(1) {
        &:before { background-color: $border-color-1 }
        .proposal__link { color: $border-color-1 }
        .proposal__icon { fill: $border-color-1 }
    }

    &:nth-child(2) {
        &:before { background-color: $border-color-2 }
        .proposal__link { color: $border-color-2 }
        .proposal__icon { fill: $border-color-2 }
    }

    &:nth-child(3) {
        &:before { background-color: $border-color-3 }
        .proposal__link { color: $border-color-3 }
        .proposal__icon { fill: $border-color-3 }
    }

    &:nth-child(4) {
        &:before { background-color: $border-color-4 }
        .proposal__link { color: $border-color-4 }
        .proposal__icon { fill: $border-color-4 }
    }

    &:nth-child(5) {
        &:before { background-color: $border-color-5 }
        .proposal__link { color: $border-color-5 }
        .proposal__icon { fill: $border-color-5 }
    }

    &:nth-child(6) {
        &:before { background-color: $border-color-6 }
        .proposal__link { color: $border-color-6 }
        .proposal__icon { fill: $border-color-6 }
    }
}

.proposal__link {
    display: block;
    color: $text--color;
    text-decoration: none;
    padding: pxToRem(15) pxToRem(65) pxToRem(10) pxToRem(10);
    border-top-width: 6px;
    border-top-style: solid;
    @include sq-transition(.2s border-bottom-color linear, .2s border-top-width linear);

    .three-cols & {
        padding-top: pxToRem(20);
    }

    &:hover,
    &:focus {
        text-decoration: none;
        .proposal__heading { text-decoration: underline }
    }
}

.proposal__heading {
    color: $text--color;
    font-weight: 600;
    font-size: pxToRem(19);
    letter-spacing: -0.2px;
    line-height: 1.35;
    margin-top: 0;
    margin-bottom: 0;

    .three-cols & {
        font-size: pxToRem(23);
    }
}

.proposal__description {
    color: $text--color;
    font-family: 'Source Sans Pro', Helvetica, Arial, Sans-serif;
    font-size: pxToRem(16);
    font-weight: 400;
    line-height: 1.55;
    margin-top: pxToRem(10);
}

.proposal__icon {
    display: block;
    height: pxToRem(32);
    width: pxToRem(32);
    position: absolute;
    right: 10px;
    bottom: 5px;
}

.proposal__title {
    border-top: 1px solid #979797;
    font-size: pxToRem(22);
    line-height: 1.45;
    padding-top: pxToRem(25);
    margin-top: 0;
    margin-bottom: pxToRem(25);
}
