/* Module: S */
// Single line comments will be removed by the CSS preprocessor
// Hint: go nuts commenting your Sass
// Multiline comments will be preserved, use them when you need to send comments
// back out to the resulting .css file.
// Tip: Most boilerplate modules follow guidelines set out in http://bem.info/ as a rule of 
// thumb. You don't have to, but it helps with consistency and any class name collisions
// with other styles. Example naming convention:
// 
// .newsletter-subscription__element { ... }
// .newsletter-subscription__element--modifier { ... }
// .newsletter-subscription__sub-element { ... }
.newsletter-subscription {
    input[type=checkbox] {
        margin-right: 8px;
    }
    fieldset br:first-of-type {
        display: none;
    }
    legend {
        padding: 0px 5px;
    }
    table {
        tr {
            background-color: transparent !important;
            border-bottom: transparent !important;
            td {
                padding-left: 0px !important;
                border-bottom: 0px !important;
                label {
                    font-weight: bold;
                }
            }
            td:before {
                display: none !important
            }
        }
    }
}