/* Module: S */
.page__wrapper,
body.inner__page #main > .page__wrapper {
    padding-left: pxToRem(40);
    padding-right: pxToRem(40);
}

.page__heading {
    font-size: pxToRem(50);
    &:after { margin-top: pxToRem(25) }
}

h2 {
    font-size: pxToRem(42);
}

.standard-btn {
  font-size: pxToRem(20);
}
