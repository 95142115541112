/* Module: S */
.tax-reform__section {
    background-color: #fff;
    margin-top: pxToRem(35);
    margin-bottom: pxToRem(35);

    > .page__wrapper {
        max-width: 67.5rem;
        display: -ms-flexbox;
        display: flex;
    }
}

.tax-model {
    position: relative;
    width: 50%;
    border-right: 2px solid #f1f1f1;
    padding-top: pxToRem(42);
    padding-bottom: pxToRem(50);
    padding-right: pxToRem(15);

    &:last-of-type {
        border-right: 0;
        padding-right: 0;
        padding-left: pxToRem(15);
    }

    &:before,
    &:after {
        content: '';
        position: absolute;
        right: pxToRem(-18);
        display: block;
        height: pxToRem(26);
        width: pxToRem(34);
        background-image: url('data:image/svg+xml;charset=utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDMyLjYzNSAzMi42MzUiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDMyLjYzNSAzMi42MzU7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iMzJweCIgaGVpZ2h0PSIzMnB4Ij4KPGc+Cgk8cGF0aCBkPSJNMzIuMTM1LDE2LjgxN0gwLjVjLTAuMjc2LDAtMC41LTAuMjI0LTAuNS0wLjVzMC4yMjQtMC41LDAuNS0wLjVoMzEuNjM1YzAuMjc2LDAsMC41LDAuMjI0LDAuNSwwLjUgICBTMzIuNDExLDE2LjgxNywzMi4xMzUsMTYuODE3eiIgZmlsbD0iIzAwYWVlZiIvPgoJPHBhdGggZD0iTTE5LjU5OCwyOS4zNTNjLTAuMTI4LDAtMC4yNTYtMC4wNDktMC4zNTQtMC4xNDZjLTAuMTk1LTAuMTk1LTAuMTk1LTAuNTEyLDAtMC43MDdsMTIuMTg0LTEyLjE4NEwxOS4yNDQsNC4xMzYgICBjLTAuMTk1LTAuMTk1LTAuMTk1LTAuNTEyLDAtMC43MDdzMC41MTItMC4xOTUsMC43MDcsMGwxMi41MzcsMTIuNTMzYzAuMDk0LDAuMDk0LDAuMTQ2LDAuMjIxLDAuMTQ2LDAuMzU0ICAgcy0wLjA1MywwLjI2LTAuMTQ2LDAuMzU0TDE5Ljk1MSwyOS4yMDZDMTkuODU0LDI5LjMwNCwxOS43MjYsMjkuMzUzLDE5LjU5OCwyOS4zNTN6IiBmaWxsPSIjMDBhZWVmIi8+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==');
        background-repeat: no-repeat;
        background-position: center center;
        @include sq-transform(rotate(90deg));
    }

    &.current-model {
        &:before { top: pxToRem(110) }
        &:after { top: 50% }
    }

    &.new-model {
        &:after { content: none }
        &:before {
            top: auto;
            left: pxToRem(-18);
            right: auto;
            bottom: pxToRem(130)
        }
    }

}

.tax-model__title,
.tax-model__heading {
    color: #363636;
    text-align: center;
    line-height: 1.5;
    margin-top: 0;
    margin-bottom: 0;
}

.tax-model__heading {
    font-size: pxToRem(18);
    min-height: pxToRem(85);
    padding-left: pxToRem(15);
    padding-right: pxToRem(15);
}

.tax-model__title {
    font-family: 'Source Sans Pro', Helvetica, Arial, Sans-serif;
    font-size: pxToRem(13);
    font-weight: 300;
    margin-top: pxToRem(16);
}

.tax-model__list {
    @extend %list-reset;
}

.tax-model__item {
    max-width: pxToRem(150);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: pxToRem(38);
    @include sq-transition(.25s min-height ease-in-out, .25 margin-top ease-in-out);
    &:nth-child(4),
    &:last-of-type { margin-bottom: 0 }
}

.tax-model__icon {
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: pxToRem(47);

    &.documents__icon          { width: pxToRem(54) }
    &.application__icon        { width: pxToRem(118) }
    &.document-review__icon    { width: pxToRem(63) }
    &.assessment__icon         { width: pxToRem(64) }
    &.printer__icon            { width: pxToRem(65) }
    &.dollar-sign__icon        { width: pxToRem(58) }
    &.key__icon                { width: pxToRem(60) }
    &.title__icon              { width: pxToRem(68) }

    &.sold-sign__icon,
    &.smile__icon,
    &.telephone__icon          { width: pxToRem(52) }
}
