/* Module: Responsive tables */
#main {
    table {
        &:not(.table-horizontal-heading) {
            border-top: 0;

            thead { display: table-header-group }

            th {
                font-size: pxToRem(18);
                font-weight: 600;
                font-family: "Montserrat", Helvetica, Arial, Sans-serif;
                color: $base-font-colour;
                padding: pxToRem(12) pxToRem(20);
                border-bottom: 1px solid #00aeef;
                text-align: left;

                &:first-of-type { padding-left: 0 }
            }

            tr {
                background-color: transparent;
                &:nth-child(even) { background-color: transparent }
            }

            td {
                display: table-cell;
                font-size: pxToRem(18);
                padding: pxToRem(20);
                border-bottom-color: #bbbbbb !important;
                &:before { content: none }
                &.active { background-color: #fff }

                &:last-of-type,
                &:first-of-type {
                    border-bottom: 1px solid currentColor;
                    padding: pxToRem(15);
                }
            }
        }

        &.table-horizontal-heading {
            th {
                font-size: pxToRem(18);
                padding: pxToRem(20);
            }

            td {
                text-align: center;
                font-size: pxToRem(18);
                padding: pxToRem(20);
                &:first-of-type { padding: pxToRem(12) pxToRem(20) }
            }
        }
    }

    .table-indicator {
        &:after { content: none }
    }
}
