/* Module: S */
#questions {
    padding: 0 pxToRem(20);
}

.questionnaires__list {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.questionnaires__item {
    width: calc(50% - 1rem);
    // display: flex;
    &:nth-last-child(2) {
        // margin-bottom: 0
    }
}

.questionnaires__link {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-direction: column;
    flex-direction: column;
    font-size: pxToRem(20);
    padding-top: pxToRem(30);
    padding-right: pxToRem(55);
    padding-bottom: pxToRem(30);
}

.questionnaires__description {
    font-size: pxToRem(16);
    margin-top: pxToRem(12);
}