/* Module: S */
.suggestions__container {
    background-color: #fff;
    padding-top: pxToRem(35);
    padding-bottom: pxToRem(33);

}

.suggestions__wrapper {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.suggestion__question {
    font-size: pxToRem(26);
    text-align: center;
    margin-bottom: pxToRem(35);
}

.suggestion__tile {
    width: calc(50% - 10.5px);
    margin-bottom: pxToRem(21);

    &:nth-last-child(2) {
        margin-bottom: 0;
    }
}

.suggestion__heading {
    font-size: pxToRem(18);
    font-weight: 300;
    bottom: pxToRem(25);
}

.suggestion__icon {
    margin-left: pxToRem(15);
    margin-top: 1px;
}
