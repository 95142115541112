/* Module: S */
.footer {
    body.inner__page & {
        margin-top: pxToRem(40);
    }
}

.footer__section {
    padding: pxToRem(20);

    > .page__wrapper {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        padding-left: 0;
        padding-right: 0;
    }
}

.footer-top {
    background-color: $footer-top--bg;
}

.footer-bottom {
    background-color: $footer-bottom--bg;
}

.social__item {
    margin-right: pxToRem(12);
    &:last-of-type { margin-right: 0 }
}

.logo__link {
    display: block;
    border-radius: pxToRem(2);
    @include sq-transition(.15s all ease-in-out);

    .footer & {
        &:hover,
        &:focus {
            outline: 0;
            background-color: $footer-top--bg;
            box-shadow: 0 0 0 5px $footer-top--bg;
        }    
    }
}

.social__link {
    display: block;
    border-radius: 50%;
    @include sq-transition(.125s all ease-in-out);

    &:hover,
    &:focus {
        outline: 0;
        background-color: $footer-bottom--bg;
        box-shadow: 0 0 0 3px $footer-bottom--bg;
    }
}

.social__icon {
    fill: $mobile_footer-link--color;
    display: block;
    height: pxToRem(34);
    width: pxToRem(34);
}

.footer__link {
    font-size: pxToRem(15);
    font-weight: 300;
    color: $mobile_footer-link--color;
    text-decoration: none;

    &:hover,
    &:focus {
        text-decoration: underline;
    }
}

.links__list {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.links__item {
    border-right: 1px solid $mobile_links-divider--color;
    padding-right: pxToRem(10);
    margin-right: pxToRem(10);
    margin-bottom: pxToRem(10);

    .footer-bottom & {
        padding-right: pxToRem(15);
        margin-right: pxToRem(15);
        margin-bottom: pxToRem(15);
    }

    &:last-of-type {
        border-right: 0;
        margin-right: 0;
        padding-right: 0;
    }
}

.social__list {
    margin-bottom: pxToRem(30);
}

.logo__item {
    -ms-flex-positive: 1;
    flex-grow: 1;
    border-right: 1px solid $mobile_links-divider--color;

    &:first-of-type {
        -ms-flex-positive: .5;
        flex-grow: .5;
        .logo__icon { margin-left: inherit }
    }

    &:last-of-type {
        -ms-flex-positive: .5;
        flex-grow: .5;
        border-right: 0;
        .logo__icon { margin-right: inherit }
    }
}

.logo__icon {
    display: block;
    height: pxToRem(38);
    margin-left: auto;
    margin-right: auto;
    &.logo-canberra { width: pxToRem(64) }
    &.logo-your-say { width: pxToRem(54) }
    &.logo-act { width: pxToRem(72) }
}

.page__update {
    color: $mobile_footer-link--color;
    font-size: pxToRem(11);
    font-weight: 300;
    margin-top: pxToRem(10);
    margin-bottom: pxToRem(20);
}
