/* Module: S */

.fancy-radio{
  font-family: 'SourceSansPro-Regular', sans-serif;
  text-align: left;
  padding-bottom:20px;

  ul{
    margin:0 0 0 5px;
    padding:0;
    list-style: none;
  }

  .sq-form-field{

    position: relative;
    outline:0;
    cursor: pointer;

      &:before{
        width:19px;
        height:19px;
        border: 1px solid $grey-dark2;
        background: $grey-light2;
        content: ' ';
        @include sq-border-radius(50%);
        position:absolute;
        top:-2px;
        left:-2px;
        margin: 0 auto 20px auto;
        z-index: 2;
      }

    &--highlight{
      &:before{
        width:21px;
        height:21px;
        top:-3px;
        left:-3px;
        border: 3px solid $grey-dark1;
      }
    }

    &--active{

      &:after{
        @include sq-border-radius(50%);
        width:9px;
        height:9px;
        top:50%;
        top:3px;
        left:3px;
        content:' ';
        background: $blue-light1;
        position:absolute;
        z-index:3;
      }
    }
  }

}

.fancy-radio li{
  width:100%;
  min-width: 200px;
  max-width: 273px;
  white-space: nowrap;
  display: inline-block;
  vertical-align: top;
  position:relative;

  & label{
    display: inline-block;
    margin-left:17px;
    cursor:pointer;
    vertical-align: middle;
    line-height: 20px;
    white-space: normal;
    margin-right: 30px;
  }

}



.fancy-radio-select{
  display:none;
}


// input
.fancy-input{
  border:0;
  border-bottom: 1px solid $blue-light1;
  max-width:100%; //adjust max width on tablet and mobile
  width: 125px;
  padding:0 10px;
  color: $blue-light1;
  outline:0;
  margin-bottom: 2px;

  &:focus,&:active{
    margin-bottom:0;
    border-bottom: 3px solid $blue-light1;
  }
}

  //input width meassurer
  .blanked{
    white-space: nowrap;
    display: inline-block;
    visibility: hidden;
    position: absolute;
    bottom: 0px;
  }

.fancy-input-container{
  position:relative;
  margin:10px 0 20px 0;
  display:inline-block;

  //ease the fading of the placeholder
  &:after{
    @include ease-it(0.3);
  }
  //fade place holder on focus with empty value
  &--faded:after{
    opacity: 0.5;
  }

  &--million{
    &:after{
      color: $blue-light1;
      position:absolute;
      content:"$0,000,000";
      top: 0;
      left:10px;
    }
  }
  &--date{
    &:after{
      color: $blue-light1;
      position:absolute;
      content:"dd/mm/yyyy";
      top: 0;
      left:10px;
    }
  }
}

.input-description{
  position: relative;
  height:1px;
  content:" ";

  &--date-range{
    height:20px;
  }
}


.form-error{
  position:relative;
  content:' ';
}
.form-error-msg, .error_notify, .input-description__content{
  position:absolute;
  font-family: 'Lato-Regular', sans-serif;
  font-size: 14px;
  color: $red;
  top:6px;
  left:10px;
}

.input-description__content{
  top: 0;
  color: $grey-dark5;
  &--error{
    color: $red;
  }
}

.error_notify{
  top: auto;
  bottom: -100px;
  left:auto;
  right: -10px;
}

.fancy-select{
  margin-bottom:50px;
  z-index:1;
  position:relative;
  & select{
    display: none;
  }
  &--ontop{
    z-index:2;
  }
}

.fancy-select__face{
  background: $grey_light2;
  height: 60px;
  cursor:default;
  font-size: 18px;
  display:inline-block;
  width: 100%;
  max-width: 312px;
  font-family: 'SourceSansPro-Regular', sans-serif;
  position: relative;

  &--active, &:hover{
    background: $grey_dark3;
  }
}

.fancy-select__face__place-holder{
    line-height:60px;
    padding: 0 20px;
    position: relative;
    white-space: nowrap;
    border:0;
    text-align: left;
    width:100%;
    z-index:100;
    background: transparent;
    outline: 0;
}

.fancy-select__face__arrow{
  position: absolute;
  top:0;
  bottom: 0;
  width: 8px;
  height: 5px;
  right: 20px;
  background: url('{{file_dest}}/triangle-1.svg') center / cover no-repeat;
  margin: auto 0;

}

.fancy-select__options{
  position: absolute;
  top: 100%;
  width:100%;
  z-index:99;
  display: none;
}
.fancy-select__option{
    background:#fff;
    width: 100%;
    height: 60px;
    border: 1px solid $grey-light2;
    border-top:1px solid transparent;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include sq-box-shadow(0 2px 5px 0 rgba(0,0,0,0.25));
    outline:0;
    &:focus{
      border:1px solid transparent;
      background: $blue-light1;
    }

}

.fancy-button-standard{
  padding: 15px 20px;
  font-family: 'Montserrat-Regular', sans-serif;
  font-size: 20px;
  background: transparent;
  margin:20px 0 0 0;
  border: 1px solid $blue-light1;
  display: table;
  outline:0;

  &:hover,&:focus{
    background: $blue-light1;
  }
}
