/* Module: S */
.proposal__container {
    padding-top: pxToRem(80);
    padding-bottom: pxToRem(65);
}

.proposal__tile {
    width: calc(25% - 15px);
    margin-bottom: 0;

    .three-cols & {
        width: 29%;

        &:nth-child(n+4) {
            margin-top: pxToRem(20);
        }
    }
}

.proposal__heading {
    @include sq-transition(.2s margin-top ease-in-out);
}

.proposal__link {
    max-width: 100%;
    padding-top: pxToRem(30);
    padding-bottom: pxToRem(25);
    padding-right: pxToRem(5);
    border-bottom: 1px solid transparent;
    @include sq-transition(.2s border ease-in-out);

    @media screen and (min-width: 80em) {
        padding-right: pxToRem(50);
    }

    &:hover,
    &:focus {
        border-bottom-color: currentColor;
        border-top-width: 12px;

        .proposal__heading {
            text-decoration: none;
            margin-top: -6px;
         }
    }
}

.proposal__icon {
    margin-top: pxToRem(25);
}

.proposal__title {
    color: $base-font-colour;
    font-size: pxToRem(42);
    padding-top: pxToRem(60);
    text-align: left;
    border-top-color: #979797;
}
